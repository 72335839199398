import React from "react";
import { Link } from 'react-router-dom'; 
const CheckBox = props => {
    const { id, type="checkbox", name, formGroupClass, value, LinkTo, valueLink, labelClassName, linkClassName,placeholder, required, className, validate, labelValue, disabled, readonly,  autocomplete='off' } = props;
 
    return (
        <div className={" " + formGroupClass}>

            {
                labelValue === undefined ? "" : (
                    <label htmlFor={id}>{labelValue}</label>
                )
            }
            <input
                id={id}
                name={name}
                type={type}
                autoComplete={autocomplete}
                required={required}
                validade={validate}
                className={className}
                autoFocus={false}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readonly}
            />
            <label htmlFor={name} className={labelClassName}>{value} <Link className={linkClassName} to={LinkTo}>{valueLink}</Link></label>
        </div>
    );
}
export default CheckBox