import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/instrumento.css";
import { Col, Container, Row } from 'react-bootstrap';
import GlossarioDataService from "../services/glossario.service";
// import TextInput from './TextInput'
// import CheckBox from './CheckBox'
// import TextArea from './TextArea'
// import Botao from './Botao'
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import { NavLink } from 'react-router-dom';
import getTraducao from '../utils/traducao';

export default class Glossarios extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Glossarios: []
            , GlossariosFiltro: []
            , letra: '-'
        }
        this.clickDay = this.clickDay.bind(this);
        this.filtrarAgenda = this.filtrarAgenda.bind(this);
    }

    clickDay = e => {
        this.setState({ letra: e.target.getAttribute('data-key') })
        this.filtrarAgenda(e.target.getAttribute('data-key'));
    }
    filtrarAgenda = (letra) => {

        if (this.state.Glossarios.length > 0) {
            this.setState({
                GlossariosFiltro: this.state.Glossarios
                .filter(x => x.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[\u0300-\u036f]/g, "").startsWith(letra) || x.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").startsWith(letra.toLowerCase()))
                .sort(function (a, b) { return ((a.descricao.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[\u0300-\u036f]/g, "") < b.descricao.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[\u0300-\u036f]/g, "")) ? -1 : (a.descricao.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[\u0300-\u036f]/g, "") > b.descricao.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[\u0300-\u036f]/g, "")) ? 1 : 0) })
            });
        }
    }

    componentDidMount() {
        this.carregarGlossarios();
    }

    carregarGlossarios() {
        let letra = (this.props.match.params.letra !== undefined ? this.props.match.params.letra : 'A');
        this.setState({ letra: letra.toUpperCase() })

        GlossarioDataService.getAll()
            .then(response => {
                this.setState({
                    Glossarios: response.data

                });
                this.filtrarAgenda(letra)
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Glossarios" ></Header>
                <div className='container-wrapper'>
                    <Container fluid={true} className="mt-2 pt-0 pb-0 Bibliografia">
                        {/* Navegação */}
                        <Row className=" pt-0 mt-0">
                            <Col className='' md={1} lg={2}></Col>
                            <Col xs={12} sm={12} md={10} lg={8} className="header-navigation text-center ">
                                <div className="main-navigation  px-auto mx-auto pr-0">
                                    <ul className=" navigation-box text-center">
                                        {/* Instrumentos | Glossário | Outros nomes | Bibliografia */}
                                        <li><NavLink to="/instrumentos" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Instrumentos")}</NavLink></li>
                                        <li><NavLink to="/glossario" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Glossário")}</NavLink></li>
                                        <li><NavLink to="/sinonimos" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Outros nomes")}</NavLink></li>
                                        <li><NavLink to="/bibliografia" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Bibliografia")}</NavLink></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={10} lg={2}></Col>
                        </Row>
                        {/* Navegação */}
                    </Container>
                    {this.state.Glossarios.length === 0
                        ?
                        <Container fluid={false}>
                            <Row className="px-auto mx-auto text-center px-3 pb-5 pt-2 ">
                                <Col>

                                <> {getTraducao("Nenhum registro localizado")}</>


                                </Col>
                            </Row>

                        </Container>
                        :
                        <>
                            <Container fluid={false} className="instrumento Glossario">
                                {/* ALFABETO */}
                                <Row className=" pt-0 mt-0">
                                    {/* <Col></Col> */}
                                    <Col xs={12} className="header-navigation ">
                                        <Container fluid={false}>
                                            <Row>
                                                <Col className="">
                                                    <ul className=" navigation-box mx-auto ">
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=A" onClick={this.clickDay} className={this.state.letra === "A" ? "current" : ""} data-key="A">A</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=B" onClick={this.clickDay} className={this.state.letra === "B" ? "current" : ""} data-key="B">B</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=C" onClick={this.clickDay} className={this.state.letra === "C" ? "current" : ""} data-key="C">C</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=D" onClick={this.clickDay} className={this.state.letra === "D" ? "current" : ""} data-key="D">D</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=E" onClick={this.clickDay} className={this.state.letra === "E" ? "current" : ""} data-key="E">E</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=F" onClick={this.clickDay} className={this.state.letra === "F" ? "current" : ""} data-key="F">F</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=G" onClick={this.clickDay} className={this.state.letra === "G" ? "current" : ""} data-key="G">G</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=H" onClick={this.clickDay} className={this.state.letra === "H" ? "current" : ""} data-key="H">H</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=I" onClick={this.clickDay} className={this.state.letra === "I" ? "current" : ""} data-key="I">I</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=J" onClick={this.clickDay} className={this.state.letra === "J" ? "current" : ""} data-key="J">J</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=K" onClick={this.clickDay} className={this.state.letra === "K" ? "current" : ""} data-key="K">K</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=L" onClick={this.clickDay} className={this.state.letra === "L" ? "current" : ""} data-key="L">L</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=M" onClick={this.clickDay} className={this.state.letra === "M" ? "current" : ""} data-key="M">M</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=N" onClick={this.clickDay} className={this.state.letra === "N" ? "current" : ""} data-key="N">N</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=O" onClick={this.clickDay} className={this.state.letra === "O" ? "current" : ""} data-key="O">O</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=P" onClick={this.clickDay} className={this.state.letra === "P" ? "current" : ""} data-key="P">P</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=Q" onClick={this.clickDay} className={this.state.letra === "Q" ? "current" : ""} data-key="Q">Q</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=R" onClick={this.clickDay} className={this.state.letra === "R" ? "current" : ""} data-key="R">R</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=S" onClick={this.clickDay} className={this.state.letra === "S" ? "current" : ""} data-key="S">S</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=T" onClick={this.clickDay} className={this.state.letra === "T" ? "current" : ""} data-key="T">T</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=U" onClick={this.clickDay} className={this.state.letra === "U" ? "current" : ""} data-key="U">U</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=V" onClick={this.clickDay} className={this.state.letra === "V" ? "current" : ""} data-key="V">V</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=W" onClick={this.clickDay} className={this.state.letra === "W" ? "current" : ""} data-key="W">W</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=X" onClick={this.clickDay} className={this.state.letra === "X" ? "current" : ""} data-key="X">X</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=Y" onClick={this.clickDay} className={this.state.letra === "Y" ? "current" : ""} data-key="Y">Y</NavLink></li>
                                                        <li className="alfabeto px-1 m-auto"><NavLink to="../glossario?letra=Z" onClick={this.clickDay} className={this.state.letra === "Z" ? "current" : ""} data-key="Z">Z</NavLink></li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    {/* <Col></Col> */}
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col className="    letra"><h1>{this.state.letra}</h1></Col>
                                </Row>
                            </Container>
                            <Container fluid={false}>
                                <Row className="px-auto mx-auto text-center px-0 pb-5 pt-2">
                                    {this.state.GlossariosFiltro.map(a =>
                                        <Col xs={12} className="text-left pt-0 pt-0  pl-1 glossario Instrumento pb-2" key={a.id}>{a.descricao}</Col>
                                    )}
                                </Row>
                            </Container>
                        </>
                    }
                </div>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
