import { getLang } from "../utils";
import http from "../utils/apiAvancada";

class PlanoDataService {
  getAll() {
    return http.get(`/Planos/published?lang=${getLang()}`);
  }

  get(id) {
    return http.get(`/planos/${id}`);
  }

  create(data) {
    return http.post("/Planos", data);
  }

  update(id, data) {
    return http.put(`/planos/${id}`, data);
  }

  delete(id) {
    return http.delete(`/planos/${id}`);
  }

  deleteAll() {
    return http.delete(`/Planos`);
  }

  findByTitle(title) {
    return http.get(`/Planos?title=${title}`);
  }
}

export default new PlanoDataService();