import { getLang } from "../utils";
import http from "../utils/apiAvancada";

class FichaTecnicaDataService {
  getAll() {
    return http.get(`/fichaTecnica?lang=${getLang()}`);
  }

}

export default new FichaTecnicaDataService();