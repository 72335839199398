import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/termo-condicoes.css";
import { Col, Container, Row } from 'react-bootstrap';
import LoginDataService from "../services/login.service";
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import getTraducao from '../utils/traducao';
import Botao from './Botao';
import TextInput from './TextInput';
export default class RecuperarSenha extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Politica: []
            , PoliticaFiltro: []
            , letra: 'A'
            , filtragem: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        // debugger
        this.setState({ isSubmiting: true })
        let cadastro =
        {
            username: event.target.Email.value,
        };
        this.setState({ message: [] })



        LoginDataService.lostPassword(cadastro).then(res => {

            if (res.status === 200) {

                localStorage.setItem('message', res.data.message);
                this.setState({ isSubmiting: false })
                alert(res.data.message);
                window.location = "/login";
            }
        }).catch(function (error) {
            // this.setShow(this);
            // var $ = require("jquery");
            let message = 'Ocorreu um erro inesperado.';
            if (error.response !== undefined) {
                message = error.response.data.message;
                // $(".has-error-message").text(message);
                alert(message);
                localStorage.setItem('message', message);
            }
            else
                if (error.message !== undefined) {
                    message = error.message;
                    // $(".has-error-message").text(message);
                    alert(message);
                    localStorage.setItem('message', message);
                }
        });


    }

    onChangeText = (event) => {
        this.setState({
            filtragem: event.target.value
        })
    }


    componentDidMount() {

    }


    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Recuperar senha" ></Header>

                <Container className="termo-condicoes pt-4">
                    <Row>
                        <Col xs={6} className="px-5"><h3>{getTraducao("Recuperar").toUpperCase()}</h3><h1>{getTraducao("senha").toUpperCase()}</h1></Col>
                        <Col xs={2} className="px-5"></Col>
                        <Col xs={4} className="px-5"></Col>

                    </Row>
                </Container>
                <Container fluid={false}>
                    <form className='cadastro' onSubmit={this.handleSubmit}>


                        <Row className="px-auto mx-auto px-3 ">
                            <Col>{getTraducao("Digite seu e-mail cadastrado no campo abaixo para receber um e-mail e escolher uma nova senha.")}</Col>
                        </Row>
                        <Row className="px-auto mx-auto px-3 mb-0">
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <TextInput
                                    id="Email"
                                    name="Email"
                                    type="Email"
                                    placeholder="Email"
                                    required="required"
                                    className="form-control py-4"
                                    formGroupClass="px-0 mb-0"
                                // validate="Cor"
                                // labelValue="E-mail"
                                />
                            </Col>
                        </Row>
                        <Row className="px-auto mx-auto px-3 ">
                            <Col>{getTraducao("Lembre-se de verificar sua caixa de spam, caso não receba o e-mail.")}</Col>
                        </Row>

                        <Row className="px-auto mx-auto px-3 ">
                            <Col>{getTraducao("Em caso de dúvidas, acesse o formulário de contato.")}</Col>
                        </Row>
                        <Row className="px-auto mx-auto px-3 pb-5">

                            <Col xs={6} sm={6} md={3} lg={3}>
                                <Botao className={"course-one__link font-size-20 lh-21"} value="ENVIAR" />
                            </Col>
                        </Row>
                    </form>
                </Container>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
