import React, { Component } from 'react';
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import BannerHome from './BannerHome';
import BannerPlanos from './BannerPlanos';
import MailChimp from './mailchimp';
import SejaBemVindo from './SejaBemVindo';
import Fase01 from './Fase01';
import Fase02 from './Fase02';
export default class Home extends Component {

  render() {

    return (
      <div className="page-wrapper">
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Home" ></Header>
        <BannerHome></BannerHome>
        <SejaBemVindo></SejaBemVindo>
        <BannerPlanos></BannerPlanos>
        <MailChimp></MailChimp>
        <Fase02></Fase02>
        <Fase01></Fase01>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div>
    );
  }

}
