import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import instrumentos from '../assets/images/musical-instruments-bass-drum-barrel-acoustic-guit-SDHULLY.png'
import comofazerpesquisa from '../assets/images/como fazer pesquisa.png'
import ideiaviolao from '../assets/images/ideia-violao.png'
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import { Container, Row, Col } from 'react-bootstrap';
import getTraducao from '../utils/traducao';

export default class SaibaMais extends Component {

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }
  render() {

    return (
      <div className="page-wrapper">
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Saiba Mais" ></Header>
        <Container className="termo-condicoes pt-4">
          <Row>
            <Col xs={6} className="">
              <h3>{getTraducao("SOBRE O")}</h3>
              <h1>{getTraducao("DICIONÁRIO")}</h1></Col>
            <Col xs={2} className=""></Col>
            <Col xs={4} className="">

            </Col>

          </Row>
        </Container>
        <div
          style={{
            // padding: 50
          }}
        >
          <Container className="pt-3">
            {/* <Row>
              <Col lg={12}>
                <h2 className="block-title__title">Dicas de uso</h2>
              </Col>
            </Row> */}
            <Row className="pt-3 pr-0  mr-0">
              <Col lg={7}>
                <p className="about-two__text pb-4" >
                {getTraducao("Existem dicionários de instrumentos musicais em várias partes do mundo, porém, o Dicionário Fontoura de Instrumentos Musicais, da maneira como foi estruturado, é único.")}
                </p>
                <p className="about-two__text pb-4" >
                {getTraducao("Alguns são genéricos e apresentam apenas os verbetes, outros são específicos (de instrumentos percussão, de instrumentos étnicos, instrumentos regionais, instrumentos de orquestra, etc.). Entre eles, poucos são ilustrados e mais raros, ainda, são os que oferecem materiais complementares como fotos, vídeos ou áudios.")}
                </p>
                <p className="about-two__text pb-4" >
                {getTraducao("O Dicionário Fontoura de Instrumentos Musicais tem a missão de, ao longo do tempo, organizar e disponibilizar um grande banco de dados composto por informações detalhadas de instrumentos musicais do mundo inteiro.")}
                </p>
                <p className="about-two__text pb-4" >
                {getTraducao("A presente edição que, atualmente, já conta com mais de 600 verbetes, será continuamente expandida a 1300 verbetes.")}
                </p>
                <p className="about-two__text pb-4" >
                {getTraducao("Esperamos que você aproveite ao máximo os conteúdos aqui disponibilizados.")}
                </p>
                <p className="about-two__text font-weight-bold" >
                {getTraducao("Boa leitura!")}
                </p>{/*<!-- /.about-two__text -->*/}
              </Col>
              <Col lg={5} className={"text-left ml-0 pl-0 pt-0 mt-0"}>
                <img className="instrumento " alt="instrumentos" src={instrumentos}></img>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="como-fazer-pesquisa">
          <Container className="pt-3">
            <Row>
              <Col lg={12}>
                <h3 className="" style={{ color: 'white' }}> {getTraducao("COMO FAZER A")}</h3>
                <h1 className="block-title__title" style={{ color: '#ffd786' }}> {getTraducao("PESQUISA")}</h1>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col lg={3} className="py-0 pr-5 pl-0 m-0">
                <img className="instrumento w-100 p-0 m-0" alt="comofazerpesquisa"  src={comofazerpesquisa}></img>
              </Col>
              <Col lg={9}>
                <p className="about-two__text pb-3" style={{ color: 'white' }}>
                {getTraducao("Para localizar um determinado instrumento você deve procurá-lo no índice alfabético.")}
                </p>
                <p className="about-two__text pb-3" style={{ color: 'white' }}>
                {getTraducao("Alguns instrumentos possuem um único nome, outros, nomes diferentes, conforme a cultura em que estão inseridos. Há, ainda, a possibilidade de um mesmo nome indicar diferentes instrumentos.")}
                </p>
                <p className="about-two__text pb-3" style={{ color: 'white' }}>
                {getTraducao("Os instrumentos que possuem diferentes nomes ou sinônimos foram organizados de modo a privilegiar o nome mais usual. Os demais nomes podem ser encontrados no índice geral de sinônimos. Deste modo, caso você não encontre um instrumento que você procura na lista principal, tente encontrá-lo no índice geral de sinônimos.")}
                </p>
                <p className="about-two__text pb-3" style={{ color: 'white' }}>
                {getTraducao("Caso você não encontre o instrumento procurado na lista geral ou no índice geral de sinônimos, possivelmente este instrumento ainda não foi incluído no dicionário. Neste caso, você poderá entrar em contato com a nossa equipe técnica e, assim que possível, sua demanda poderá ser atendida.")}
                </p>
                <p className="about-two__text pb-3" style={{ color: 'white' }}>
                {getTraducao("Alguns instrumentos incluem somente o verbete. Outros incluem, também, fotos, áudios ou vídeos, disponibilizados pelos respectivos botões de acesso, quando destacados.")}



                </p>{/*<!-- /.about-two__text -->*/}
              </Col>
            </Row>
          </Container>
        </div>




        <Container className="pt-3 usando-o-glossario" style={{
           marginTop: -120
        }}>
          <Row>
            <Col lg={12}>
              <h3 className="">{getTraducao("USANDO O ").toUpperCase()}</h3>
              <h1 className="block-title__title">{getTraducao("GLOSSÁRIO").toUpperCase()} </h1>
            </Col>
          </Row>
          <Row className="pt-3 pr-0  mr-0">
            <Col lg={7}>
              <p className="about-two__text pb-4" >
              {getTraducao("Termos técnicos referentes a partes de instrumentos, modos de execução, acessórios, entre outras especificidades, poderão ser encontrados no glossário, que integra nosso dicionário.")}


              </p>
              <p className="about-two__text pb-4" >
              {getTraducao("Algumas partes de instrumentos recebem diferentes nomes em diferentes partes do mundo. Por exemplo, a parte do violão onde se situa a cravelheira é chamada de “cabeça” por alguns e de “mão” por outros. Nesses casos, optamos pela nomenclatura mais usual e de uso frequente nas fontes de pesquisa de cada instrumento.")}


              </p>
              <p className="about-two__text pb-4" >
              {getTraducao("Caso você tenha alguma dúvida a respeito de algum termo técnico utilizado nos verbetes, consulte o glossário.")}
              </p>
              {/* <p className="about-two__text pb-4" >
                  A presente edição que, atualmente, já conta com mais de 600 verbetes, será continuamente expandida a 1300 verbetes.
                </p>
                <p className="about-two__text pb-4" >
                  Esperamos que você aproveite ao máximo os conteúdos aqui disponibilizados.
                </p>
                <p className="about-two__text font-weight-bold" >
                  Boa leitura!
                </p><!-- /.about-two__text --> */}
            </Col>
            <Col lg={5} className={"text-left ml-0 pl-0 pt-0 mt-0"}>
              <img className="instrumento ideiaviolao"  alt="ideiaviolao"  src={ideiaviolao}></img>
            </Col>
          </Row>
        </Container>




        <div className="site-header__decor-inner-1"
          style={{
            margin: 'auto', width: '60%' , marginTop: -80
          }}
        ></div>

        <div
          style={{
            backgroundColor: "#c96f28", padding: 50, zIndex: -1//,   marginTop: -120
          }}
        >

          <Container className="pt-3 quero-contribuir">
            <Row>
              <Col lg={12}>
                <h3 className="" style={{ color: 'white', textTransform: 'uppercase' }}> {getTraducao("Quero contribuir com uma").toUpperCase()}</h3>
                <h1 className="block-title__title" style={{ color: '#ffd786' }}> {getTraducao("IDEIA ou MATERIAL").toUpperCase()}</h1>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col lg={8}>
                <p className="about-two__text pb-3" style={{ color: 'white' }}>
                {getTraducao("Caso você não encontre o instrumento que procura ou deseja conhecer, ou mesmo um termo técnico a ele relacionado, entre em contato com nossa equipe e envie-nos suas sugestões.")}

                </p>{/*<!-- /.about-two__text -->*/}
                <p className="about-two__text" style={{ color: 'white' }}>

                {getTraducao("Você conhece um instrumento musical de características singulares, ainda não contemplado em nosso dicionário? Entre em contato e conte-nos mais a respeito dele.")}
                {getTraducao("Caso você possua fotos, vídeos ou áudios de instrumentos musicais e queira compartilhá-los conosco, entre em contato para que possamos avaliar a inclusão de seu material em nosso banco de dados.")}

                </p>{/*<!-- /.about-two__text -->*/}
              </Col>
              {/* <Col lg={4} className="w-100 h-100 py-0 my-0" style={{
                backgroundColor: "#e08c58", padding: 50, zIndex: 1, position: 'relative', bottom: '-118px'
              }}>
                <br /><br /><br /><br /><br /><br />

              </Col> */}
            </Row>
          </Container>

        </div>
        <Container className="pt-3 ponderacoes-tecnicas" style={{    paddingTop: '15px', height: '523px', marginBottom: '00px',    marginTop: 35}}>
          <Row>
            <Col lg={8}>
              <h3 className="">{getTraducao("PONDERAÇÕES").toUpperCase()} </h3>
              <h1 className="block-title__title">{getTraducao("TÉCNICAS").toUpperCase()} </h1>
            </Col>

            <Col lg={4} className="sobre-a-bibliografia rodrigo" style={{padding: '55px 55px 55px 55px',
                backgroundColor: "#e08c58",   zIndex: 1, position: 'relative', bottom: '118px'
              }}>

              <h3 className="" style={{ color: 'white' }}>{getTraducao("SOBRE A").toUpperCase()}  </h3>
              <h1 className="block-title__title" style={{ color: '#ffd786' }}>{getTraducao("BIBLIOGRAFIA").toUpperCase()} </h1>
            </Col>
          </Row>
          <Row className="sobre-a-bibliografia-2"
          style={{position: 'relative',  top: '-118px'}}
          >
            <Col lg={8} >
              <p className="about-two__text pb-4" >
              {getTraducao("Um mesmo instrumento pode ser tratado no feminino ou no masculino, a depender da língua. Buscamos referir tais instrumentos pela maneira mais usual.")}
              </p>
              <p className="about-two__text pb-4" >
              {getTraducao("Os instrumentos apresentam especificidades de execução quanto à lateralidade (mão, braço, perna, direita ou esquerda). Em todo caso, e de modo geral, as descrições da técnica performática utilizadas ao longo do dicionário referem-se à maneira mais comum de tocar o instrumento.")}
              </p>
              <p className="about-two__text pb-4" >
              {getTraducao("Adotamos, na maioria das vezes, o sistema métrico de medida, exceto no caso de alguns instrumentos, como a cuíca, por exemplo, cujas medidas são normalmente determinadas segundo o sistema imperial inglês (polegadas).")}
              </p>

            </Col>



            <Col  className={" P-4 pt-0"} style={{padding: '55px 55px 155px 55px',
                backgroundColor: "#e08c58",  zIndex: -1, position: 'relative', bottom: '0'
              }}>

              <p className="about-two__text pb-4  " style={{ color: '#fff' }}>
              {getTraducao("Os conteúdos dos verbetes são baseados em pesquisas diversificadas, amparadas na bibliografia referencial da área, bem como em pesquisas detalhadas in loco.")}
                   </p>
              <p className="about-two__text pb-4  "  style={{ color: '#fff' }}>
              {getTraducao("Consulte a bibliografia referencial, no menu ‘bibliografia’.")}
              </p>
            </Col>
          </Row>
        </Container>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div >
    );
  }

}
