import React from "react";

const TextArea = props => {
    const { id, name, required, className, labelValue, disabled, readonly, placeholder,
        maxLength, onChange, value, onBlur, formGroupClass, MsgErro, labelClass = "", rows = 8 } = props;
    //const { onChange, ...restEvents } = events;
    //const { contClass, fieldClass, errorClass } = classes;
    //const { setFields, addField, fields, validateField, errors } = useContext(
    //    Form
    //);
    //const fieldError = errors[id];

    return (

        <div className={"form-group " + formGroupClass + " withoutmask"}>
            {
                labelValue === undefined ? "" : (
                    <label htmlFor={id} className={labelClass}>{labelValue}</label>
                )
            }
            <textarea
                id={id}
                name={name}
                required={required}
                className={className}
                maxLength={maxLength}
                onChange={onChange}
                value={value}
                disabled={disabled}
                readOnly={readonly}
                onBlur={onBlur}
                placeholder={placeholder}
                rows={rows}
            />
            {(MsgErro !== undefined && MsgErro !== "" )? <p className="erro">{MsgErro}</p> : ""}
        </div>
    );
}
export default TextArea