
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container,  } from 'react-bootstrap';
import { getLang } from '../utils';


class BannerHome extends Component {
    constructor(props) {
        super(props);
        ;

}

    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {
        const options = {
            items: 1,
            nav: true,
            navContainerClass: 'banner-carousel-btn',
            navClass: ['banner-carousel-btn__left-btn', 'banner-carousel-btn__right-btn'],
            navText: ['<i class="kipso-icon-left-arrow"></i>', '<i class="kipso-icon-right-arrow"></i>'],
            rewind: true,
            autoplay: false,
            slideBy: 1,
            dots: true,
            dotsEach: true,
            dotData: true
        };

        return (

            <Container fluid={true} className="banner-wrapper m-0 p-0">
               <OwlCarousel ref="car" className="banner-one banner-carousel__one no-dots owl-theme owl-carousel " {...options} >
                    <div className="banner-one__slide banner-one__slide-one">
                        <Container fluid={true} className="p-0">
                            <div className="row no-gutters text-center">

                                <div className="col-xl-12 text-center  ">
                                    <img src={`../assets/images/banner1_${getLang()}.png`} alt="" className="banner m-auto" />
                                </div>{/*<!-- /.col-xl-12 -->*/}

                            </div>{/*<!-- /.row -->*/}
                        </Container>
                    </div>{/*<!-- /.banner-one__slide -->*/}
                    <div className="banner-one__slide banner-one__slide-two">
                        <Container fluid={true} className="p-0">
                            <div className="row no-gutters text-center">

                                <div className="col-xl-12 text-center  ">
                                    <img src={`../assets/images/banner2_${getLang()}.png`} alt="" className="banner m-auto" />
                                </div>{/*<!-- /.col-xl-12 -->*/}

                            </div>{/*<!-- /.row -->*/}
                        </Container>
                    </div>{/*<!-- /.banner-one__slide -->*/}


                </OwlCarousel >


            </Container >

        );
    }
}
export default BannerHome;