
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getEmail, getNome, isLogin } from '../utils';
import getTraducao from '../utils/traducao';
import br from '../assets/images/BR.png'
import us from "../assets/images/US.png"

class NavTop extends Component {
    constructor(props) {
        super(props);
        ;
    }


    changeLang = (lang) => {
        localStorage.setItem('Idioma', lang);
        window.location.reload();
    }
    componentDidMount() {
        ;
    }

    render() {
        return (

            <div className="topbar-one">
                <div className="container">
                    <div className="topbar-one__left font-weight-bold ">

                        <span className='d-none d-lg-block d-xl-block'>
                            contato@dicionariodeinstrumentos.com.br
                        </span>
                        {/* <Link to="#">(041) 3333.3333</Link> */}
                    </div>{/*<!-- /.topbar-one__left -->*/}
                    <div className="topbar-one__right">
                        {isLogin()
                            ?
                            <div className="topbar-one__left font-weight-bold pr-3">
                                {getNome() === null ? getEmail() : getNome()}
                            </div>
                            : <>
                                <Link to="../login">{getTraducao("Entrar")}</Link>
                                <Link to="../cadastre">{getTraducao("Cadastrar")}</Link>
                            </>

                        }
                        <Link to="#" onClick={() => this.changeLang('pt-BR')}><img src={br} alt="pt-BR" className="banner m-auto" /></Link>
                        <Link to="#" onClick={() => this.changeLang('en')}><img src={us} alt="US" className="banner m-auto" /></Link>
                    </div>{/*<!-- /.topbar-one__right -->*/}
                </div>{/*<!-- /.container -->*/}

            </div>

        );
    }
}
export default NavTop;