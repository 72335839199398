import React/*, { useContext, useEffect }*/ from "react";
import {/* Button, Col,*/ Row } from 'react-bootstrap';
import getTraducao from "../utils/traducao";

function Botao(props) {
    const { value, submit = "true", FunctionClickCall, className = "btn btn-primary mr-2 col-12 mt-4",
        with_row = false, icon, BotaoLink = "false", disabled = false } = props;

    return (
        < div >
            {

                BotaoLink === "false"
                    ?
                    with_row
                        ?

                        <Row>
                            <div className="form-group col-sm-12" >
                                <button className={className}
                                    type={submit === "true" ? "submit" : "button"}
                                    disabled={disabled}
                                    onClick={() => FunctionClickCall()}>
                                    {
                                        icon !== undefined
                                            ?
                                            <i className={icon} />
                                            :
                                            ""
                                    }
                                    {getTraducao(value)}
                                </button>
                            </div>
                        </Row>
                        : <div>
                            {submit === "true"
                                ?
                                <button className={className}
                                    type={submit === "true" ? "submit" : "button"}
                                    disabled={disabled}
                                >
                                    {
                                        icon !== undefined
                                            ?
                                            <i className={icon} />
                                            :
                                            ""
                                    }
                                    {getTraducao(value)}
                                </button>
                                :
                                <button className={className}
                                    type={submit === "true" ? "submit" : "button"}
                                    disabled={disabled}
                                    onClick={() => FunctionClickCall()}>
                                    {
                                        icon !== undefined
                                            ?
                                            <i className={icon} />
                                            :
                                            ""
                                    }
                                    {getTraducao(value)}
                                </button>
                            }
                        </div>
                    :
                    <div>
                        <button className={className}
                            type="button"
                            disabled={disabled}
                            onClick={FunctionClickCall}>
                            {getTraducao(value)}
                        </button>
                    </div>
            }
        </div >


    );
}
export default Botao;


