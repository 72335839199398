import React from "react";
import getTraducao from "../utils/traducao";

// import InputMask from "react-input-mask";
const TextInput = props => {
    const { id, type, name, placeholder, required, className, validate,
        labelValue, disabled, readonly, maxLength, onChange, autocomplete = 'off', dataMatch, dataMatchError,
        value, defaultValue, onBlur,onFocus, min, max,MsgErro, dataMinLength, maskPlaceholder, mask, visible = "true",
        labelClass = "", formGroupClass="" } = props;

    const style= {
        display: visible === "true"? "block" : "none"
    }
    return (
        type==='hidden'
        ?
        <input
        id={id}
        name={name}
        type={type}
        value={value}
        defaultValue={defaultValue}
        />
        :
        
        mask === undefined
        ?


        <div className={"form-group " + formGroupClass + " withoutmask"}>
        {
            labelValue === undefined || visible === "false" ? "" : (
                <label htmlFor={id}  className={labelClass}>{getTraducao(labelValue)}</label>
            )
        }
        <input
            id={id}
            name={name}
            type={ type==='date' ? 'text': type}
            onFocus={onFocus}
            min={min}
            max={max}
            data-min-length={dataMinLength}
            autoComplete={autocomplete}
            required={required}
            validade={validate}
            className={className}
            data-match={dataMatch}
            data-match-error={dataMatchError}

            placeholder={getTraducao(placeholder)}
            maxLength={maxLength}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            readOnly={readonly}
            visible={visible}
            onBlur={onBlur}
            style={style}
        />
        {(MsgErro !== undefined && MsgErro !== "" )? <p className="erro">{MsgErro}</p> : ""}
    </div>
    :
    <div className={"form-group " + formGroupClass + " mask"}>

            {
                labelValue === undefined || visible === "false" ? "" : (
                    <label htmlFor={id} className={labelClass}>{labelValue} </label>
                )
            }
            {/* <InputMask
                id={id}
                name={name}
                type={type}
                autoComplete={autocomplete}
                required={required}
                validade={validate}
                className={className}              
                mask={mask}
                maskPlaceholder={maskPlaceholder}
                maxLength={maxLength}
                onChange={onChange}
                value={value}
                min={min}
                max={max}
                defaultValue={defaultValue}
                disabled={disabled}
                readOnly={readonly}
                visible={visible}
                onBlur={onBlur}
                style={style}
            /> */}
            {MsgErro !== "" ? <p className="erro">{MsgErro}</p> : ""}
        </div>
    );
}
export default TextInput