
import React, { Component } from 'react';
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import imgInstrumentos from "../assets/images/our-collection-of-ethnic-instruments-so-much-fun-t-FH5DDRW.png"
import getTraducao from '../utils/traducao';

class SejaBemVindo extends Component {
  constructor(props) {
    super(props);
    ;

  }

  handleLogout = () => {

  }

  componentDidMount() {


  }

  render() {
    return (
      <div>
        <section className="about-two">
          <Container>
            <Row>
              <Col xl={6}>
                <div className="about-two__content">
                  <div className="block-title text-left">
                    <h2 className="block-title__title font-size-60 lh-59">{getTraducao("Seja bem-vindo ao Dicionário Fontoura de Instrumentos Musicais")}
                          </h2>{/*<!-- /.block-title__title -->*/}
                  </div>{/*<!-- /.block-title -->*/}
                  <div className="about-two__text">
                    <div className="pb-4 font-size-22 lh-28">{getTraducao("Cada povo constrói seus instrumentos musicais e com eles se relaciona de diferentes maneiras.")}</div>
                    <div className="pb-4 font-size-22 lh-28">{getTraducao("Estudá-los é mais do que explorar as suas formas, modos de tocar, sonoridades ou outras especificidades. É, também, conhecer parte da história, da sociologia, da geografia, da antropologia, entre outros aspectos culturais de seu povo de origem.")}</div>
                    <div className="pb-4 font-size-22 lh-28">{getTraducao("Aqui, você vai viajar por diversas partes do mundo, por meio dos seus instrumentos musicais.")}</div>
                    <div className="pb-4 font-size-22 lh-28">{getTraducao("O material de que são feitos, as técnicas de execução, a função onde estão inseridos (rituais, festividades, meditação, educação, ou simples lazer, entre outras), a complexidade dos instrumentos, sua evolução, o modo como se expandiram mundo afora são aspectos curiosos e relevantes da história da humanidade.")}</div>
                    <br></br>
                    <div className="pb-4 font-size-28 lh-28 text-uppercase font-weight-bold font-italic">{getTraducao("Quer experimentar esta viagem?")}</div>
                    <div className="pb-4 font-size-22 lh-28"><Link className={"font-size-22 lh-28 link font-weight-bold"} to={"./cadastre"}>{getTraducao("Clique aqui")} </Link> {getTraducao("faça o seu cadastro e acesse, gratuitamente, alguns verbetes do Dicionário Fontoura de Instrumentos Musicais.")}</div>
                  </div>{/*<!-- /.about-two__text -->*/}
                  <br /><br />
                  <Link to="./saibamais" className="thm-btn ">{getTraducao("Saiba mais")}</Link>{/*<!-- /.thm-btn -->*/}
                </div>{/*<!-- /.about-two__content -->*/}
              </Col >{/*<!-- /.col-lg-6 -->*/}
              <Col  xl={6} className="justify-content-xl-end justify-content-sm-center">
                <div className="about-two__image_ pt-5 mt-5">
                  <span className="about-two__image-dots_ "></span>{/*<!-- /.about-two__image-dots -->*/}
                  <img src={imgInstrumentos} alt="" />
                </div>{/*<!-- /.about-two__image -->*/}
              </Col>{/*<!-- /.col-lg-6 -->*/}
            </Row>{/*<!-- /.row -->*/}
          </Container>{/*<!-- /.container -->*/}
        </section>{/*<!-- /.about-two -->*/}

      </div>

    );
  }
}
export default SejaBemVindo;