import React, { Component } from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="search-popup">
                <div className="search-popup__overlay custom-cursor__overlay">
                    <div className="cursor"></div>
                    <div className="cursor-follower"></div>
                </div>
                <div className="search-popup__inner">
                    <form action="#" className="search-popup__form">
                        <input type="text" name="search" placeholder="Digite aqui para pesquisar ...." />
                        <button type="submit"><i className="kipso-icon-magnifying-glass"></i></button>
                    </form>
                </div>
            </div>
        );
    }
}
export default Footer;