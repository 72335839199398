import http from "../utils/apiAvancada";

class PsgDataService {


  getSession() {
    return http.post(`/pagseguro/session/`);
  }

  createPayments(data) {
    return http.post("/pagseguro/payments", data);
  }


}

export default new PsgDataService();