import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/termo-condicoes.css";
import { Col, Container, Row } from 'react-bootstrap';
import PoliticaDataService from "../services/politica.service";
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import Paragraph from './Paragraph';
import getTraducao from '../utils/traducao';
import { scrollToSmooth } from '../utils';
export default class PoliticaPrivacidade extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Politica: []
            , PoliticaFiltro: []
            , letra: 'A'
            , filtragem: ''
        }
    }

    onChangeText = (event) => {
        this.setState({
            filtragem: event.target.value
        })
    }


    componentDidMount() {
        this.carregarPolitica();


    }

    carregarPolitica() {

        PoliticaDataService.getAll()
            .then(response => {
                var element = response.data;
                {
                    for (var i = 0; i < element.length; i++) {

                        this.setState({
                            Politica: this.state.Politica.concat(
                                [
                                    {
                                        Id: element[i].id,
                                        Titulo: element[i].titulo,
                                        Texto: element[i].texto
                                    }])
                        })
                    }
                    scrollToSmooth();
                }
                scrollToSmooth();
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Gerais de Uso" ></Header>

                <Container className="termo-condicoes pt-4">
                    <Row>
                        <Col xs={6} className="px-5"><h3>{getTraducao("POLÍTICA DE")}</h3><h1>{getTraducao("PRIVACIDADE")}</h1></Col>
                        <Col xs={2} className="px-5"></Col>
                        <Col xs={4} className="px-5">

                        </Col>

                    </Row>
                </Container>
                <Container fluid={false}>
                    <Row className="px-auto mx-auto text-center px-3 pb-5 pt-2">
                        <>
                            {this.state.Politica.map(
                                a => <Col xs={12} className="text-left pt-0 pt-0 termo-condicoes" key={a.Id}>


                                    <Paragraph dangerous={true} value={a.Texto}></Paragraph>
                                    <div>&nbsp;</div>
                                </Col>

                            )}
                        </>
                    </Row>
                </Container>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
