import http from "../utils/apiAvancada";

class UserDataService {
  get() {
    return http.get("/user");
  }
  getAdminBoard() {
    return http.get("/user/adminBoard");
  }
//   get(id) {
//     return http.get(`/planos/${id}`);
//   }

enviarEmail(data) {
    return http.post("/usuarios/enviarEmail", data);
  }

//   update(id, data) {
//     return http.put(`/planos/${id}`, data);
//   }

//   delete(id) {
//     return http.delete(`/planos/${id}`);
//   }

//   deleteAll() {
//     return http.delete(`/Planos`);
//   }

//   findByTitle(title) {
//     return http.get(`/Planos?title=${title}`);
//   }
}

export default new UserDataService();