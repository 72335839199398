import { getLang } from "../utils";
import http from "../utils/apiAvancada";
// import { getRole } from "../utils";
class InstrumentoDataService {
  getAll() {
    // if (getRole() === 'ROLE_ADMIN')
      return http.get(`/instrumentos?lang=${getLang()}`);
    // else
    //   return http.get(`/instrumentos/published?lang=${getLang()}`);
  }

  get(id) {
    return http.get(`/instrumentos/${id}`);
  }

  create(data) {

    return http.post("/instrumentos", data);
  }

  update(id, data) {
    return http.put(`/instrumentos/${id}`, data);
  }

  delete(id) {
    return http.delete(`/instrumentos/${id}`);
  }

  deleteAll() {
    return http.delete(`/instrumentos`);
  }

  findByTitle(title, lang) {
    // if (getRole() === 'ROLE_ADMIN')
    return http.get(`/instrumentos?nome=${title}&lang=${getLang()}`);
    // else
    // return http.get(`/instrumentos/published?nome=${title}&lang=${getLang()}`);
  }
  findByTitleAndLang(title, lang) {
    return http.get(`/instrumentos/lang?nome=${title}&lang=${getLang()}`);
  }
}

export default new InstrumentoDataService();