import { getLang } from "../utils";
import http from "../utils/apiAvancada";

class PlanoDataService {
  getAll() {
    return http.get(`/sinonimos?lang=${getLang()}`);
  }

}

export default new PlanoDataService();