
import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/plugins/kipso-icons/style.css";
class Header extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {     
        return (
            <div>
                <Helmet><title>{(this.props.title !== undefined ? this.props.title + " | " : "") + 'Cântaro Arte&Educação'}</title></Helmet>
            </div>
        );
    }
}
export default Header;