
import React, { Component } from 'react';
import reactLogo from '../assets/images/logo.png';
import { Link, NavLink } from 'react-router-dom';
import { isLogin, logout } from '../utils';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import getTraducao from '../utils/traducao';
// import { isLogin } from '../utils';

class NavMain extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {
        logout();
        this.setState({
            isLogin: false
        })
        window.location.reload();
    }

    stricky = () => {
        var $ = require("jquery");

        if ($('.stricky').length) {
            $('.stricky').addClass('original').clone(true).insertAfter('.stricky').addClass('stricked-menu').removeClass('original');
        }
    }
    componentDidMount() {
        this.stricky();
    }

    render() {
        return (
            <>
                <header className="site-header site-header__header-one ">

                    {/* <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky"> */}
                    {/* <div className="container clearfix"> */}

                    {/*<!-- Collect the nav links, forms, and other content for toggling -->*/}
                    <Navbar bg="light" expand="xl" className="main-navigation" sticky="top" fixed="top" >
                        {/*<!-- Brand and toggle get grouped for better mobile display -->*/}
                        <Container fluid={false} className={''}>
                            <Navbar.Brand href={isLogin() ? "./instrumentos" : "/"}><img src={reactLogo} className="main-logo" width="160" alt="Awesome" />


                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" /> {/* text-right collapse navbar-collapse flex-grow-1 text-right*/}
                            <Navbar.Collapse id="navbarScroll" className='header-navigation '>
                                <Nav className=" navigation-box mr-auto">
                                    <div className="header__social d-none d-lg-block d-md-block d-sm-block">
                                        <Link to='#' className="pl-0"><i className="fab fa-facebook-f"></i></Link>
                                        {/* <Link to='#'><i className="fab fa-whatsapp"></i></Link> */}
                                        <Link to='#' className="pl-2"><i className="fab fa-instagram"></i></Link>
                                        {/* <Link to='#'><i className="fab fa-twitter"></i></Link> */}
                                    </div>{/*<!-- /.header__social -->*/}
                                    {/* <button className="menu-toggler" data-target=".main-navigation">
                                    <span className="kipso-icon-menu"></span>
                                </button> */}
                                    {/* </div><!-- /.logo-box --> */}
                                </Nav>

                                {/* <Nav className=" navigation-box"> */}
                                <Nav className="navigation-box ml-auto my-2 my-lg-0"
                                    style={{ maxHeight: '250px' }}
                                    navbarScroll activeKey="/">
                                    <Nav.Item><NavLink to="/" exact activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Home")}</NavLink></Nav.Item>
                                    <Nav.Item><NavLink to="/sobre" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Sobre nós")}</NavLink></Nav.Item>
                                    <Nav.Item><NavLink to="/saibamais" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Saiba mais")}</NavLink></Nav.Item>
                                    {
                                        isLogin()
                                            ?
                                            <Nav.Item><NavLink to="./nova-assinatura" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Assinatura")}</NavLink></Nav.Item>
                                            :
                                            <Nav.Item><NavLink to="./assinatura" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Assinatura")}</NavLink></Nav.Item>
                                    }
                                    {/* <Nav.Item><NavLink to="/contato" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Contato")}</NavLink></Nav.Item> */}

                                    {isLogin()
                                        ?
                                        <>
                                            <NavDropdown title={getTraducao("Dicionário")} id="nav-dropdown" menuRole="text-link" className="" >
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/instrumentos" >{getTraducao("Instrumentos")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/glossario" >{getTraducao("Glossário")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/sinonimos" >{getTraducao("Outros nomes")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/bibliografia" >{getTraducao("Bibliografia")}</NavDropdown.Item>
                                                {/* <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/bibliografia" >{getTraducao("Bibliografia")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/minhas-assinaturas" >{getTraducao("Minhas assinaturas")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/alterar-senha" >{getTraducao("Alterar Senha")}</NavDropdown.Item> */}
                                            </NavDropdown>
                                            <NavDropdown title={getTraducao("Perfil")} id="nav-dropdown" menuRole="text-link" className="" >
                                                {/* <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/instrumentos" >{getTraducao("Instrumentos")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/glossario" >{getTraducao("Glossário")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/sinonimos" >{getTraducao("Outros nomes")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/bibliografia" >{getTraducao("Bibliografia")}</NavDropdown.Item> */}
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/minhas-assinaturas" >{getTraducao("Minhas assinaturas")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/meus-dados" >{getTraducao("Meus dados")}</NavDropdown.Item>
                                                <NavDropdown.Item activeClassName="current" activeStyle={{ opacity: 0 }} href="/alterar-senha" >{getTraducao("Alterar Senha")}</NavDropdown.Item>

                                            </NavDropdown>
                                            <Nav.Item>
                                                <Nav.Link tag={Link} className="" to="#" onClick={() => this.handleLogout()}>{getTraducao("Sair")}</Nav.Link>
                                            </Nav.Item>
                                        </>
                                        : <></>
                                    }

                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>{/*<!-- /.navbar-collapse -->*/}

                    {/* <div className="right-side-box"> */}
                    {/* <Link className="header__search-btn search-popup__toggler" to="#"><i className="kipso-icon-magnifying-glass"></i> */}
                    {/*<!-- /.kipso-icon-magnifying-glass --></Link>*/}
                    {/*  </div><!-- /.right-side-box -->*/}
                    {/* </div> */}
                    {/*<!-- /.container -->*/}
                    {/* </nav> */}
                    <div className="site-header__decor">
                        <div className="site-header__decor-row">
                            <div className="site-header__decor-single">
                                <div className="site-header__decor-inner-1"></div>{/*<!-- /.site-header__decor-inner -->*/}
                            </div>{/*<!-- /.site-header__decor-single -->*/}
                            <div className="site-header__decor-single">
                                <div className="site-header__decor-inner-2"></div>{/*<!-- /.site-header__decor-inner -->*/}
                            </div>{/*<!-- /.site-header__decor-single -->*/}
                            <div className="site-header__decor-single">
                                <div className="site-header__decor-inner-3"></div>{/*<!-- /.site-header__decor-inner -->*/}
                            </div>{/*<!-- /.site-header__decor-single -->*/}
                        </div>{/*<!-- /.site-header__decor-row -->*/}
                    </div>{/*<!-- /.site-header__decor -->*/}
                </header>
            </>
        );
    }
}
export default NavMain;