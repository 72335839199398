import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch } from 'react-router-dom';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import Home from './components/Home';
import Login from './components/Login';


import GenericNotFound from './components/GenericNotFound';
import Assinatura from './components/Assinatura';
import Contato from './components/Contato';
import Sobre from './components/Sobre';
import SaibaMais from './components/SaibaMais';
import Instrumentos from './components/Instrumentos';
import cadastre from './components/CadastreSe';
import Instrumento from './components/Instrumento';
import bibliografia from './components/bibliografia';
import sinonimos from './components/sinonimos';
import glossario from './components/glossario';
import TermosCondicoes from './components/termos-condicoes';
import PoliticaPrivacidade from './components/politica-privacidade';
import MinhasAssinaturas from './components/minhas-assinaturas';
import NovaAssinatura from './components/NovaAssinatura';
import CodigoAtivacao from './components/CodigoAtivacao';
import recuperarSenha from './components/RecuperarSenha';
import alterarSenha from './components/AlterarSenha';
import MeusDados from './components/MeusDados';
import { version } from '../package.json';
import Loading from './loading';
class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {/* PUBLIC ROUTES */}
                    <PublicRoute component={Home} path="/" exact restricted={false} />
                    <PublicRoute component={Sobre} path="/sobre" exact restricted={false} />
                    <PublicRoute component={Assinatura} path="/assinatura" exact restricted={false} />
                    <PublicRoute component={SaibaMais} path="/saibamais" exact restricted={false} />
                    <PublicRoute component={Contato} path="/contato" exact restricted={false} />
                    <PublicRoute component={Login} path="/login" exact restricted={false} />
                    <PrivateRoute component={Instrumentos} path="/instrumentos"                         exact restricted={false} />
                    <PrivateRoute component={Instrumentos} path="/instrumentos/:letra"                  exact restricted={false} />
                    {/* <PrivateRoute component={Instrumentos} path="/instrumentos?letra=:letra"            exact restricted={false} /> */}

                    {/* <PrivateRoute component={Instrumento} path="/instrumento?instrumento=:instrumento"  exact restricted={false} /> */}
                    <PrivateRoute component={Instrumento} path="/instrumento"              exact restricted={false} />
                    <PrivateRoute component={Instrumento} path="/instrumento/:instrumento"              exact restricted={false} />

                    <PrivateRoute component={glossario} path="/glossario" exact restricted={false} />

                    <PrivateRoute component={sinonimos} path="/sinonimos" exact restricted={false} />
                    <PrivateRoute component={glossario} path="/glossario/:letra" exact restricted={false} />
                    <PrivateRoute component={sinonimos} path="/sinonimos/:letra" exact restricted={false} />
                    <PrivateRoute component={bibliografia} path="/bibliografia" exact restricted={false} />
                    <PrivateRoute component={MinhasAssinaturas} path="/minhas-assinaturas" exact restricted={false} />
                    <PrivateRoute component={MeusDados} path="/meus-dados" exact restricted={false} />
                    <PrivateRoute component={NovaAssinatura} path="/nova-assinatura" exact restricted={false} />
                    <PrivateRoute component={CodigoAtivacao} path="/codigo-ativacao" exact restricted={false} />
                    <PrivateRoute component={NovaAssinatura} path="/nova-assinatura/:plano" exact restricted={false} />
                    <PublicRoute component={cadastre} path="/cadastre" exact restricted={false} />
                    <PublicRoute component={cadastre} path="/cadastre/:codigo" exact restricted={false} />
                    <PublicRoute component={recuperarSenha} path="/recuperar-senha/" exact restricted={false} />      {/** Recuperar a senha pelo link, vindo da tela de login */}
                    <PublicRoute component={alterarSenha} path="/recuperar-alterar-senha/"  restricted={false} /> {/** Alterar a senha vindo do email */}
                    <PrivateRoute component={alterarSenha} path="/alterar-senha/" exact restricted={false} />         {/** Alterar a senha a partir de usuário autenticado */}
                    <PublicRoute component={TermosCondicoes} path="/termos-condicoes" exact restricted={false} />
                    <PublicRoute component={PoliticaPrivacidade} path="/politica-privacidade" exact restricted={false} />
                    <PublicRoute component={GenericNotFound} />
                </Switch>
            </BrowserRouter>
        )
    }
}
//  const item = document.getElementById('app');
//  ReactDOM.render(<App />, item);

export default App;