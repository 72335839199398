import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';

import PlanoDataService from "../services/plano.service";
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextInput from './TextInput';
import Select from './Select';
import Botao from './Botao';
import LoginDataService from "../services/login.service";
import getTraducao from '../utils/traducao';
import { getLang, validaCpfCnpj } from '../utils';
class CadastreSe extends Component {


  constructor(props) {
    super(props);

    this.state = {
      message: [],
      escolhas: [],
      planos: [],
      isSubmiting: false,
      Genero: "-1",
      // Pais: getLang() === 'pt-BR' ? "Brasil" : "",
      show: false
    }
    this.setPlano = this.setPlano.bind(this);
    this.setGenero = this.setGenero.bind(this);
    this.setPlanoDataKey = this.setPlanoDataKey.bind(this);
    this.onFocusDate = this.onFocusDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isPlanosSelecionado = this.isPlanosSelecionado.bind(this);
    // this.setShow = this.setShow.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  handleLogout = () => {
    ;
  }
  onChangeValue(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle
    });
  }
  componentDidMount() {

    this.setState({
      codigo: (this.props.match.params.codigo !== undefined ? this.props.match.params.codigo.trim() : undefined)
    })

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }

  createElements(n, valor) {
    var elements = [];
    for (var i = 1; i <= n; i++) {
      elements.push(<li key={i + valor + n}><><div style={{ fontWeight: 'bolder' }}>{i}x de R$ {valor / i} no cartão ou no boleto bancário</div>{i > 1 ? <>*acréscimo de R$ 2,00 em cada parcela no boleto</> : <></>}</></li>);
    }
    return elements;
  }
  getPlanos() {
    PlanoDataService.getAll()
      .then(response => {
        this.setState({
          planos: response.data
        });
        response.data.map(e => {
          this.setState({
            escolhas: [...this.state.escolhas, { 'id': e.id, 'value': 0 }]
          })
          // console.log(e.id)
          return e.id
        })
        this.setState({ message: [] })


      })
      .catch(e => {
        console.log(e);
      });
  }
  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    debugger
    // this.setState({ isSubmiting: true })
    let cadastro =
    {
      nome: event.target.Nome.value,
      escola: event.target.Escola.value,
      // CPF: event.target.CPF.value,
      dataNascimento: event.target.DataNascimento.value,
      Profissao: event.target.Profissao.value,
      Genero: event.target.Genero.value,
      email: event.target.Email.value,
      codigo: (event.target.codigo.value !== "" ? event.target.codigo.value.trim() : undefined),
      password: event.target.Senha.value,
      // CEP: event.target.CEP.value,
      areaCode: event.target.areaCode.value,
      number: event.target.number.value,
      // Endereco: event.target.Endereco.value,
      // Numero: event.target.Numero.value,
      // Complemento: event.target.Complemento.value,
      // Bairro: event.target.Bairro.value,
      Cidade: event.target.Cidade.value,
      Estado: event.target.Estado.value,
      Pais: event.target.Pais.value,

    };
    this.setState({ message: [] })
    let messages = []

    // if (!validaCpfCnpj(event.target.CPF.value))
    //   messages.push({ 'id': 3, 'value': 'CNPJ/CPF Inválido, favor verificar.' })

    // if (this.state.Plano === 0)
    //   messages.push({ 'id': 9, 'value': 'CNPJ/CPF Inválido, favor verificar.' })

    if (event.target.Senha.value !== event.target.SenhaConfirma.value)
      messages.push({ 'id': 4, 'value': 'As senhas devem ser iguais.' })

    this.setState({ message: messages })

    if (messages.length === 0) {
      LoginDataService.signup(cadastro).then(res => {
        // console.log(res);
        // debugger
        if (res.status === 200) {
          alert(res.data.message);
          this.setState({ isSubmiting: false })
          // this.setState({isSubmiting: true})
          window.location = "/login";
        }
      }).catch(function (error) {
        // this.setShow(this);
        var $ = require("jquery");
        let message = 'Ocorreu um erro inesperado.';
        if (error.response !== undefined) {
          message = error.response.data.message;
          $(".has-error-message").text(message);

        }
        else
          if (error.message !== undefined) {
            message = error.message;
            $(".has-error-message").text(message);
          }
      });
    }
    // else {
    //   this.setShow(this)
    // }
  }

  // setShow(event) {
  //   // debugger
  //   // this.setState({ show: false, isSubmiting: false })
  //   // this.setState({})
  // }

  onFocusDate(event) {
    event.target.type = (event.target.type === 'text' ? 'date' : event.target.type)
  }

  setPlano(event) {

    this.setState({
      Plano: event.target.value
    });
  }
  setGenero(event) {

    this.setState({
      Genero: event.target.value
    });
  }

  setPlanoDataKey(event) {

    let remove = this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key));
    this.state.escolhas.map(data => (data.value = 0));
    this.setState({
      escolhas: [...this.state.escolhas.filter((_, i) => i !== remove), { id: parseInt(event.target.dataset.key), value: 1 }],
      Plano: event.target.dataset.key//event.target.getAttribute('data-key')
    })
  }
  isPlanosSelecionado(value) {

    let select = this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(value));

    let retorno = -2
    if ((this.state.escolhas !== undefined) && (select !== -1))
      retorno = this.state.escolhas.filter((_, i) => i === select)[0].value
    return retorno;
  }
  render() {

    return (
      <div className="page-wrapper">

        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Assinatura" ></Header>
        {!this.state.isSubmiting
          ?
          <form className='cadastro' onSubmit={this.handleSubmit}>

            <section className="course-osne__top-titsle home-sone assinatura-tops pt-5">
              <div className="container ">
                <Row>
                  <Col></Col>
                  <Col xs={10}>
                    <div className="block-title mb-0 font-size-24 lh-38 ">
                      {getTraducao("Oferecemos a você ou à sua instituição de ensino ou outra, algumas opções de planos para que você possa escolher o que melhor se adapta à sua necessidade.")}<br></br><br></br>
                      {getTraducao("Em todos os planos você tem o acesso a todos os recursos do dicionário constituindo de verbetes, fotos, vídeos, áudios e ilustrações. A diferença de um plano para o outro consiste somente no período de tempo que você terá disponibilidade de acesso e o custo.")}<br></br><br></br>
                      <h2 className="block-dtitle__titsle font-size-30">
                        {getTraducao("escolha a sua opção e faça a sua assinatura!")}</h2>{/*<!-- /.block-title__title -->*/}
                    </div>{/*<!-- /.block-title -->*/}
                  </Col>
                  <Col></Col>
                </Row>
              </div>{/*<!-- /.container -->*/}

            </section>{/*<!-- /.course-one__top-title -->*/}
            <section className="course-ones course-one__teacher-details assinatura-planoss home-one">
              <Container fluid={false} className="mt-2 pt-5 pb-5  ">
                <Row>

                  <Col xs={12}>
                    <TextInput
                      id="Nome"
                      name="Nome"
                      type="text"
                      // min={parseInt(format(new Date(), 'yyyy') - 70)}
                      // max={parseInt(format(new Date(), 'yyyy'))}
                      placeholder={getTraducao("Nome Completo")}
                      required="required"
                      className="form-control py-4"
                      // validate="anoFabricacao"
                      labelValue={getTraducao("Nome")}
                      // maxLength="4"
                      // defaultValue={this.state.AnoFabricacao}
                      // onChange={this.OnChangeAnoFabricacao}
                      formGroupClass="px-0"
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>
                  <Col xs={12}>
                    <TextInput
                      id="Escola"
                      name="Escola"
                      type="text"
                      // min={parseInt(format(new Date(), 'yyyy') - 70)}
                      // max={parseInt(format(new Date(), 'yyyy'))}
                      placeholder={getTraducao("Instituição")}
                      // required="required"
                      className="form-control py-4"
                      // validate="anoFabricacao"
                      labelValue={getTraducao("Instituição")}
                    // maxLength="4"
                    // defaultValue={this.state.AnoFabricacao}
                    // onChange={this.OnChangeAnoFabricacao}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6}>
                    <TextInput
                      id="DataNascimento"
                      name="DataNascimento"
                      type="date"
                      placeholder={getTraducao("Data de Nascimento")}
                      required="required"
                      className="form-control py-4"
                      formGroupClass="px-0"
                      // validate="Cor"
                      labelValue={getTraducao("Data Nascimento")}
                      // maxLength="15"
                      // defaultValue={this.state.Cor}
                      onFocus={this.onFocusDate}
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>
                  <Col xs={12}>
                    <TextInput
                      id="Profissao"
                      name="Profissao"
                      type="text"
                      // min={parseInt(format(new Date(), 'yyyy') - 70)}
                      // max={parseInt(format(new Date(), 'yyyy'))}
                      placeholder={getTraducao("Profissão")}
                      required="required"
                      className="form-control py-4"
                      // validate="anoFabricacao"
                      labelValue="Profissao"
                      // maxLength="4"
                      // defaultValue={this.state.AnoFabricacao}
                      // onChange={this.OnChangeAnoFabricacao}
                      formGroupClass="px-0"
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Select
                      id="Genero"
                      name="Genero"
                      className="form-control "
                      labelValue={"Gênero"}
                      onChange={this.setGenero}
                      onBlur={this.setGenero}
                      placeholder="SELECIONE UMA OPÇÃO"
                      defaultValue={this.state.Sexo}
                      required="required">
                      <option value="-1" disabled>SELECIONE UMA OPÇÃO</option>

                      <option data-key={"M"} key={"M"} value={"M"}>{getTraducao("Masculino")}</option>
                      <option data-key={"F"} key={"F"} value={"F"}>{getTraducao("Feminino")}</option>
                      <option data-key={"O"} key={"O"} value={"O"}>{getTraducao("Outro (qual)")}</option>
                      <option data-key={"P"} key={"P"} value={"P"}>{getTraducao("Prefiro não dizer")}</option>

                    </Select>
                  </Col>
                  {this.state.Genero === 'O'
                    ?
                    <Col xs={6}>
                      <TextInput
                        id="OutroGenero"
                        name="OutroGenero"
                        type="text"
                        // min={parseInt(format(new Date(), 'yyyy') - 70)}
                        // max={parseInt(format(new Date(), 'yyyy'))}
                        placeholder="Outro Gênero"
                        required="required"
                        className="form-control py-4"
                        // validate="anoFabricacao"
                        labelValue="Outro Gênero"
                        // maxLength="4"
                        // defaultValue={this.state.AnoFabricacao}
                        // onChange={this.OnChangeAnoFabricacao}
                        formGroupClass="px-0"
                      // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                      />
                    </Col>
                    : <></>}
                </Row>
                <Row>
                  <Col xs={4} sm={4} md={4} lg={2}>
                    <TextInput
                      id="areaCode"
                      name="areaCode"
                      type="number"
                      placeholder="DDD"
                      required="required"
                      className="form-control py-4"
                      formGroupClass="px-0"
                      // validate="Cor"
                      labelValue="DDD"
                      maxLength="2"
                    // defaultValue={this.state.Cor}
                    // onChange={this.OnChangeCor}
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>

                  <Col xs={8} sm={8} md={8} lg={4}>
                    <TextInput
                      id="number"
                      name="number"
                      type="tel"
                      placeholder="Telefone"
                      required="required"
                      className="form-control py-4"
                      formGroupClass="px-0"
                      // validate="Cor"
                      labelValue="Telefone"
                    // maxLength="15"
                    // defaultValue={this.state.Cor}
                    // onChange={this.OnChangeCor}
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={9} sm={9} md={6} lg={6}>
                    <TextInput
                      id="cidade"
                      name="Cidade"
                      type="text"
                      placeholder="Cidade"
                      required="required"
                      className="form-control py-4"
                      //labelValue="E-Mail"
                      validate="cidade"
                      labelValue="Cidade"
                      value={this.state.Cidade}
                    // onChange={this.OnChangeCidade}
                    // readonly="readonly"
                    />
                  </Col>

                  <Col xs={3} sm={3} md={3} lg={3} >
                    <TextInput
                      id="estado"
                      name="Estado"
                      type="text"
                      placeholder="Estado"
                      className="form-control py-4"
                      required="required"
                      //labelValue="E-Mail"
                      validate="estado"
                      labelValue="Estado"
                      value={this.state.Estado}
                    // onChange={this.OnChangeEstado}
                    // readonly="readonly"
                    />
                  </Col>
                  <Col xs={12} sm={12} md={3} lg={3} >
                    <TextInput
                      id="Pais"
                      name="Pais"
                      type="text"
                      placeholder="Pais"
                      required="required"
                      className="form-control py-4"
                      //labelValue="E-Mail"
                      validate="Pais"
                      labelValue="Pais"
                      value={this.state.Pais}
                      // onChange={this.onChangeValue}
                    // readonly="readonly"
                    />
                  </Col>

                  <Col xs={12}>
                    <TextInput
                      id="Email"
                      name="Email"
                      type="Email"
                      placeholder="Email"
                      required="required"
                      className="form-control py-4"
                      formGroupClass="px-0"
                      // validate="Cor"
                      labelValue="E-mail"
                    // maxLength="15"
                    // defaultValue={this.state.Cor}
                    // onChange={this.OnChangeCor}
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>
                  <Col xs={6}>
                    <TextInput
                      id="Senha"
                      name="Senha"
                      type="password"
                      placeholder="Senha"
                      required="required"
                      className="form-control py-4"
                      formGroupClass="px-0"
                      // validate="Cor"
                      labelValue="Senha"
                    // maxLength="15"
                    // defaultValue={this.state.Cor}
                    // onChange={this.OnChangeCor}
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>

                  <Col xs={6}>
                    <TextInput
                      id="SenhaConfirma"
                      name="SenhaConfirma"
                      type="password"
                      placeholder="Confirmação de Senha"
                      required="required"
                      className="form-control py-4"
                      formGroupClass="px-0"
                      // validate="Cor"
                      labelValue="Confirmação de Senha"
                    // maxLength="15"
                    // defaultValue={this.state.Cor}
                    // onChange={this.OnChangeCor}
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <TextInput
                      id="codigo"
                      name="codigo"
                      type="text"
                      placeholder={getTraducao("Código de assinatura")}
                      className="form-control py-4"
                      formGroupClass="px-0"
                      // validate="Cor"
                      labelValue={getTraducao("Código de assinatura")}
                    // maxLength="15"
                    // defaultValue={this.state.Cor}
                    // onChange={this.OnChangeCor}
                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                    />
                  </Col>
                </Row>
                {true
                  ?
                  <Row>
                    <Col xs={12}>

                      <div className="has-error has-error-message control-label px-4" />
                      <div className={"has-error control-label"}>
                        <ul>
                          <>
                            {

                              this.state.message.map((a, i) => <li key={i}>{a.value}</li>)
                            }
                          </>
                        </ul>
                      </div>
                    </Col>

                  </Row>
                  :
                  <>
                    SELECIONE UM PLANO
                    <Row>
                      {/* <Select
                                id="Plano"
                                name="Plano"
                                className="form-control"
                                labelValue="Plano"
                                onChange={this.setPlano}
                                onBlur={this.setPlano}
                                // value={this.state.Plano}
                                defaultValue={this.state.Plano}
                                required="required">
                                <option value="" disabled>SELECIONE UM PLANO</option>
                                {
                                    this.state.planos.map(e =>
                                        <option data-key={e.id} key={e.id} value={e.id}>{'[' + e.titulo + '] R$ ' + e.valor + ' - ' + e.descricao}</option>
                                    )
                                }
                            </Select> */}
                      {this.state.planos && this.state.planos.length > 0
                        ?

                        <>
                          {
                            this.state.planos.map(data =>
                              <Col className="item mx-auto px-auto " xs={4} key={data.id}>


                                <div className="course-one__single color-1 ">
                                  <div className={"course-one__image color-" + data.order}>
                                    {/* <img src={"./assets/images/" + data.imagem} alt="" /> */}
                                  </div>{/*<!-- /.course-one__image -->*/}
                                  <div className="course-one__content">
                                    <h2 className="course-one__title font-size-36 lh-50 ">{data.titulo}</h2>

                                    <div className="course-one__stars">
                                      <span className="course-one__stars-wrap">

                                      </span>
                                      {/*<!--span className="course-one__count">4.8</span-->*/}{/*<!-- /.course-one__count -->*/}
                                      {/*<!--span className="course-one__stars-count">250</span-->*/}{/*<!-- /.course-one__stars-count -->*/}
                                    </div>{/*<!-- /.course-one__stars -->*/}
                                    <div className="course-one__meta font-size-36 lh-36">
                                      R$ {data.valor}<br />
                                    </div>{/*<!-- /.course-one__meta -->*/}
                                    <div className="course-one__meta-description-interna font-size-18 lh-21">
                                      {data.conteudo}
                                    </div>

                                    <Link to="#" index={data.id} data-key={data.id} id={data.id} onClick={this.setPlanoDataKey} className={"course-one__link font-size-20 lh-21 " + (this.isPlanosSelecionado(parseInt(data.id)) === 1 ? 'active' : 'non-active')} >Escolher</Link>
                                    {/*<!-- /.course-one__link -->*/}
                                  </div>{/*<!-- /.course-one__content -->*/}
                                </div>{/*<!-- /.course-one__single -->*/}
                              </Col>
                            )
                          }

                        </>
                        //   </OwlCarousel>
                        :
                        <Container>
                        <Row>
                          <Col>
                            <p>{getTraducao("Carregando planos").toUpperCase()}</p>
                          </Col>
                        </Row>
                      </Container>
                      }
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <div className="has-error has-error-message control-label px-4" />
                        <div className={"has-error control-label"}>
                          <ul>
                            <li>{getTraducao("Mensagens").toUpperCase()}</li>
                            <>
                              {
                                this.state.message.map((a, i) => <li key={i}>{a.value}</li>)
                              }
                            </>
                          </ul>
                        </div>
                      </Col>

                    </Row>
                    <Row className=" pt-0 mt-0">
                      <Col>
                        {/* <DirectPayment
                                    env={this.state.env}
                                    session={this.state.session}
                                    extraAmount={this.state.extraAmount}
                                    reference={this.state.reference}
                                    creditCard={this.state.creditCard}
                                    sender={this.state.sender}
                                    shipping={this.state.shipping}
                                    billing={this.state.billing}
                                    items={this.state.items} */}
                        {/* onError={this.onError.bind(this)} */}
                        {/* onSubmit={this.onSubmit.bind(this)} */}
                        {/* /> */}
                      </Col>
                    </Row>
                  </>
                }
                <Row>
                  <Col xs={6}>
                  </Col>

                  <Col xs={6}>
                    <Botao className={"course-one__link font-size-20 lh-21"} value="Salvar" />
                  </Col>
                </Row>
              </Container>
            </section>
            {/* <section className="  course-ones course-one__teacher-details assinatura-planoss home-one">
                    <Container fluid={false} className="mt-2 pt-5 pb-5  ">
                            PagSeguro

                    </Container>{/*<!-- /.container -->*/}
            {/* </section> */}
          </form >
          :
          <section className="course-osne__top-titsle home-sone assinatura-tops pt-5">
            <div className="container ">
              <Row>
                <Col>Processando seu cadastro. Aguarde...</Col>
              </Row>
            </div>
          </section>
        }
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div>
    );
  }
}

export default CadastreSe