import React, { Component } from 'react';
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row, Col } from 'react-bootstrap';
import img01 from "../assets/images/site-fase-2_03.png"
import img02 from "../assets/images/site-fase-2_04.png"
import img03 from "../assets/images/site-fase-2_05.png"

import getTraducao from '../utils/traducao';

class Fase02 extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {

        return (
            <div>

                <section className="fase-one">
                    <Container>
                        <Row>
                            <Col xs={4} sm={2} md={2} lg={4} className={"font-italic pt-5 pb-0 text-right font-size-19 lh-76 text-uppercase"}>
                                <div className={"divisoria"}>{getTraducao("FASE 2")}</div>
                            </Col>{/*<!-- /.col-lg-6 -->*/}
                            <Col xs={8} sm={10} md={10} lg={8} className="d-flex pt-4 mt-3 pb-3 px-0">
                                <Container fluid={true}>
                                    <Row>
                                        <Col xl={3} >{getTraducao("Apoio")}<br></br><img alt="img04"     height={"100px"}   className={"p-0 m-0 text-right"} src={img01} /></Col>
                                        <Col xl={2}>{getTraducao("Realização")}<br></br><img alt="img01" height={"100px"}   className={"p-0 m-0 text-left"} src={img02} /></Col>
                                        <Col xl={3}>{getTraducao("Incentivo")}<br></br><img alt="img02"  height={"100px"}   className={"p-0 m-0 text-left"} src={img03} /></Col>
                                        
                                    </Row>
                                    <Row className="pb-2">



                                    </Row>
                                    <Row>
                                        <Col><div className="font-size-14 lh-14">{getTraducao("Projeto aprovado no Programa Estadual de Fomento e Incentivo à Cultura - PROFICE da Secretaria de Estado da Cultura | Governo do Paraná.")}</div></Col>
                                    </Row>
                                </Container>
                            </Col>{/*<!-- /.col-lg-6 -->*/}
                        </Row>{/*<!-- /.row -->*/}
                    </Container>{/*<!-- /.container -->*/}
                </section>
            </div>
        );
    }
}
export default Fase02;