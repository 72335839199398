import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/termo-condicoes.css";
import { Col, Container, Row } from 'react-bootstrap';
import LoginDataService from "../services/login.service";
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import getTraducao from '../utils/traducao';
import UserDataService from "../services/user.service";
import TextInput from './TextInput';
import Select from './Select';
export default class RecuperarSenha extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: [],
            escolhas: [],
            planos: [],
            isSubmiting: false,
            Genero: "-1",
            sender: {},
            nome: "Rodrigo de Campos Brito",
            // Pais: getLang() === 'pt-BR' ? "Brasil" : "",
            show: false
          }
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.setPlano = this.setPlano.bind(this);
        this.setGenero = this.setGenero.bind(this);
        // this.setPlanoDataKey = this.setPlanoDataKey.bind(this);
        this.onFocusDate = this.onFocusDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.isPlanosSelecionado = this.isPlanosSelecionado.bind(this);
        // this.setShow = this.setShow.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
    }
    getSender() {
        UserDataService.get()
            .then(response => {
                let userData = response.data;

                this.setState({

                    sender: userData,

                    // shipping: {
                    //     type: 3,
                    //     cost: 0.00,
                    //     street: userData.Endereco !== null ? userData.Endereco : "",
                    //     number: userData.Numero !== null ? userData.Numero : "",
                    //     complement: userData.Complemento !== null ? userData.Complemento : "",
                    //     district: userData.Bairro !== null ? userData.Bairro : "",
                    //     city: userData.Cidade !== null ? userData.Cidade : "",
                    //     state: userData.Estado !== null ? userData.Estado : "",
                    //     country: userData.nome !== null ? userData.Pais : "",
                    //     postalCode: userData.CEP !== null ? userData.CEP.replace('.', '').replace('-', '') : "",
                    // },

                    // billing: {
                    //     street: userData.Endereco !== null ? userData.Endereco : "",
                    //     number: userData.Numero !== null ? userData.Numero : "",
                    //     complement: userData.Complemento !== null ? userData.Complemento : "",
                    //     district: userData.Bairro !== null ? userData.Bairro : "",
                    //     city: userData.Cidade !== null ? userData.Cidade : "",
                    //     state: userData.Estado !== null ? userData.Estado : "",
                    //     country: userData.Pais !== null ? userData.Pais : "",
                    //     postalCode: userData.CEP !== null ? userData.CEP.replace('.', '').replace('-', '') : "",
                    // },
                })



            })
            .catch(e => {
                console.log(e);
            });
    }
    setGenero(event) {

        this.setState({
          Genero: event.target.value
        });
      }
      onFocusDate(event) {
        event.target.type = (event.target.type === 'text' ? 'date' : event.target.type)
      }
    onChangeValue(e) {
        const searchTitle = e.target.value;

        this.setState({
          searchTitle: searchTitle
        });
      }
    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        // debugger
        this.setState({ isSubmiting: true })
        let cadastro =
        {
            username: event.target.Email.value,
        };
        this.setState({ message: [] })



        LoginDataService.lostPassword(cadastro).then(res => {

            if (res.status === 200) {

                localStorage.setItem('message', res.data.message);
                this.setState({ isSubmiting: false })
                alert(res.data.message);
                window.location = "/login";
            }
        }).catch(function (error) {
            // this.setShow(this);
            // var $ = require("jquery");
            let message = 'Ocorreu um erro inesperado.';
            if (error.response !== undefined) {
                message = error.response.data.message;
                // $(".has-error-message").text(message);
                alert(message);
                localStorage.setItem('message', message);
            }
            else
                if (error.message !== undefined) {
                    message = error.message;
                    // $(".has-error-message").text(message);
                    alert(message);
                    localStorage.setItem('message', message);
                }
        });


    }

    onChangeText = (event) => {
        this.setState({
            filtragem: event.target.value
        })
    }


    componentDidMount() {
        this.getSender();
    }


    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title={getTraducao("Meus dados")}></Header>

                <Container className="termo-condicoes pt-4">
                    <Row>
                        <Col xs={6} className="px-5"><h3>{getTraducao("Meus").toUpperCase()}</h3><h1>{getTraducao("dados").toUpperCase()}</h1></Col>
                        <Col xs={2} className="px-5"></Col>
                        <Col xs={4} className="px-5"></Col>

                    </Row>
                </Container>
                <Container fluid={false}>
                    <section className="course-ones course-one__teacher-details assinatura-planoss home-one">
                        <Container fluid={false} className="mt-2 pt-5 pb-5  ">
                            <Row>

                                <Col xs={12}>
                                    <TextInput
                                        id="Nome"
                                        name="Nome"
                                        type="text"
                                        readonly="readOnly"
                                        value={this.state.sender.nome}
                                        // min={parseInt(format(new Date(), 'yyyy') - 70)}
                                        // max={parseInt(format(new Date(), 'yyyy'))}
                                        placeholder={getTraducao("Nome Completo")}
                                        required="required"
                                        className="form-control py-4"
                                        // validate="anoFabricacao"
                                        labelValue={getTraducao("Nome")}
                                        // maxLength="4"
                                        // defaultValue={this.state.AnoFabricacao}
                                        // onChange={this.OnChangeAnoFabricacao}
                                        formGroupClass="px-0"
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <TextInput
                                        id="Escola"
                                        name="Escola"
                                        type="text"
                                        readonly="readOnly"
                                        value={this.state.sender.escola}
                                        // min={parseInt(format(new Date(), 'yyyy') - 70)}
                                        // max={parseInt(format(new Date(), 'yyyy'))}
                                        placeholder={getTraducao("Instituição")}
                                        // required="required"
                                        className="form-control py-4"
                                        // validate="anoFabricacao"
                                        labelValue={getTraducao("Instituição")}
                                    // maxLength="4"
                                    // defaultValue={this.state.AnoFabricacao}
                                    // onChange={this.OnChangeAnoFabricacao}
                                    />
                                </Col>

                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <TextInput
                                        id="DataNascimento"
                                        name="DataNascimento"
                                        type="date"
                                        value={this.state.sender.dataNascimento}
                                        readonly="readOnly"
                                        placeholder={getTraducao("Data de Nascimento")}
                                        required="required"
                                        className="form-control py-4"
                                        formGroupClass="px-0"
                                        // validate="Cor"
                                        labelValue={getTraducao("Data Nascimento")}
                                        // maxLength="15"
                                        // defaultValue={this.state.Cor}
                                        onFocus={this.onFocusDate}
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <TextInput
                                        id="Profissao"
                                        name="Profissao"
                                        type="text"
                                        readonly="readOnly"
                                        value={this.state.sender.Profissao}
                                        // min={parseInt(format(new Date(), 'yyyy') - 70)}
                                        // max={parseInt(format(new Date(), 'yyyy'))}
                                        placeholder={getTraducao("Profissão")}
                                        required="required"
                                        className="form-control py-4"
                                        // validate="anoFabricacao"
                                        labelValue="Profissao"
                                        // maxLength="4"
                                        // defaultValue={this.state.AnoFabricacao}
                                        // onChange={this.OnChangeAnoFabricacao}
                                        formGroupClass="px-0"
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <Select
                                        id="Genero"
                                        name="Genero"
                                        readonly="readOnly"
                                        disabled="disabled"
                                        className="form-control "
                                        labelValue={"Gênero"}
                                        onChange={this.setGenero}
                                        onBlur={this.setGenero}
                                        placeholder="SELECIONE UMA OPÇÃO"
                                        value={this.state.sender.Genero}
                                        required="required">
                                        <option value="-1" disabled>SELECIONE UMA OPÇÃO</option>

                                        <option data-key={"M"} key={"M"} value={"M"}>{getTraducao("Masculino")}</option>
                                        <option data-key={"F"} key={"F"} value={"F"}>{getTraducao("Feminino")}</option>
                                        <option data-key={"O"} key={"O"} value={"O"}>{getTraducao("Outro (qual)")}</option>
                                        <option data-key={"P"} key={"P"} value={"P"}>{getTraducao("Prefiro não dizer")}</option>

                                    </Select>
                                </Col>
                                {this.state.sender.Genero === 'O'
                                    ?
                                    <Col xs={6}>
                                        <TextInput
                                            id="OutroGenero"
                                            name="OutroGenero"
                                            type="text"
                                            readonly="readOnly"
                                            value={this.state.sender.OutroGenero}
                                            // min={parseInt(format(new Date(), 'yyyy') - 70)}
                                            // max={parseInt(format(new Date(), 'yyyy'))}
                                            placeholder="Outro Gênero"
                                            required="required"
                                            className="form-control py-4"
                                            // validate="anoFabricacao"
                                            labelValue="Outro Gênero"
                                            // maxLength="4"
                                            // defaultValue={this.state.AnoFabricacao}
                                            // onChange={this.OnChangeAnoFabricacao}
                                            formGroupClass="px-0"
                                        // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                        />
                                    </Col>
                                    : <></>}
                            </Row>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={2}>
                                    <TextInput
                                        id="areaCode"
                                        name="areaCode"
                                        type="number"
                                        placeholder="DDD"
                                        value={this.state.sender.areaCode}
                                        readonly="readOnly"
                                        required="required"
                                        className="form-control py-4"
                                        formGroupClass="px-0"
                                        // validate="Cor"
                                        labelValue="DDD"
                                        maxLength="2"
                                    // defaultValue={this.state.Cor}
                                    // onChange={this.OnChangeCor}
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>

                                <Col xs={8} sm={8} md={8} lg={4}>
                                    <TextInput
                                        id="number"
                                        name="number"
                                        value={this.state.sender.number}
                                        type="tel"
                                        readonly="readOnly"
                                        placeholder="Telefone"
                                        required="required"
                                        className="form-control py-4"
                                        formGroupClass="px-0"
                                        // validate="Cor"
                                        labelValue="Telefone"
                                    // maxLength="15"
                                    // defaultValue={this.state.Cor}
                                    // onChange={this.OnChangeCor}
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={9} sm={9} md={6} lg={6}>
                                    <TextInput
                                        id="cidade"
                                        name="Cidade"
                                        value={this.state.sender.Cidade}
                                        type="text"
                                        readonly="readOnly"
                                        placeholder="Cidade"
                                        required="required"
                                        className="form-control py-4"
                                        //labelValue="E-Mail"
                                        validate="cidade"
                                        labelValue="Cidade"
                                    // onChange={this.OnChangeCidade}
                                    // readonly="readonly"
                                    />
                                </Col>

                                <Col xs={3} sm={3} md={3} lg={3} >
                                    <TextInput
                                        id="estado"
                                        name="Estado"
                                        value={this.state.sender.Estado}
                                        type="text"
                                        readonly="readOnly"
                                        placeholder="Estado"
                                        className="form-control py-4"
                                        required="required"
                                        //labelValue="E-Mail"
                                        validate="estado"
                                        labelValue="Estado"

                                    // onChange={this.OnChangeEstado}
                                    // readonly="readonly"
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={3} lg={3} >
                                    <TextInput
                                        id="Pais"
                                        name="Pais"
                                        value={this.state.sender.Pais}
                                        type="text"
                                        readonly="readOnly"
                                        placeholder="Pais"
                                        required="required"
                                        className="form-control py-4"
                                        //labelValue="E-Mail"
                                        validate="Pais"
                                        labelValue="Pais"

                                    // onChange={this.onChangeValue}
                                    // readonly="readonly"
                                    />
                                </Col>

                                <Col xs={12}>
                                    <TextInput
                                        id="Email"
                                        name="Email"
                                        type="Email"
                                        value={this.state.sender.email}
                                        readonly="readOnly"
                                        placeholder="Email"
                                        required="required"
                                        className="form-control py-4"
                                        formGroupClass="px-0"
                                        // validate="Cor"
                                        labelValue="E-mail"
                                    // maxLength="15"
                                    // defaultValue={this.state.Cor}
                                    // onChange={this.OnChangeCor}
                                    // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                    />
                                </Col>

                            </Row>
                        </Container>
                    </section>
                </Container>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
