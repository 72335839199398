const { getLang } = require(".");

var traducao = require("./config");
const  getTraducao = function () {

        var a = 0 < arguments.length && arguments[0] !== void 0 ? arguments[0] : "sandbox";//,
        // b = 1 < arguments.length && arguments[1] !== void 0 ? arguments[1] : "base";
        var lang = getLang();
        // debugger/
        try {
            try {
                // if (a === 'Outros Nomes')
                //     debugger
                var retorno = traducao[a][lang];
                var retorno2 = (traducao[a]['pt-BR'] === undefined ? a : traducao[a]['pt-BR'])
                // if (retorno === retorno2) console.log(a);
                return retorno === undefined ? retorno2: retorno;
            }
            catch {
                 console.info(a);
                return a;
            }
        } catch (error) {
            return ""
        }// finally {
        //     return traducao[a]['pt-BR']
        // }

    };
    export default getTraducao

