import React, { Component } from 'react';
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row, Col } from 'react-bootstrap';
import getTraducao from '../utils/traducao';
import LoginDataService from "../services/login.service";
class MailChimp extends Component {
    constructor(props) {
        super(props);
        ;
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isSubmiting: true })

        let cadastro =
        {
            email: event.target.Email.value
        };
        this.setState({ message: [] })

        LoginDataService.registerNewsletter(cadastro).then(res => {

            if (res.status === 200) {
                var $ = require("jquery");

                $(".mc-form__response").text(res.data.message);
                // alert();
                // window.location = "/";
            }
        }).catch(function (error) {
            // this.setShow(this);
            // var $ = require("jquery");

            let message = 'Ocorreu um erro inesperado.';
            if (error.response !== undefined) {
                message = error.response.data.message;
                // $(".has-error-message").text(message);
                alert(message);
                localStorage.setItem('message', message);
            }
            else
                if (error.message !== undefined) {

                    message = error.message;
                    // $(".has-error-message").text(message);
                    alert(message);
                    localStorage.setItem('message', message);
                }
        });


    }
    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {

        return (
            <div>

                <section className="mailchimp-one">
                    <Container>
                        <Row>
                            <Col lg={5}>
                                <div className="mailchimp-one__content">
                                    <div className="mailchimp-one__icon">
                                        <i className="kipso-icon-email"></i>{/*<!-- /.kipso-icon-email -->*/}
                                    </div>{/*<!-- /.mailchimp-one__icon -->*/}
                                    <h2 className="mailchimp-one__title">{getTraducao("Cadastre seu e-mail")} <br /> {getTraducao("e receba novidades")}</h2>{/*<!-- /.mailchimp-one__title -->*/}
                                </div>{/*<!-- /.mailchimp-one__content -->*/}
                            </Col>{/*<!-- /.col-lg-6 -->*/}
                            <Col lg={7} className="d-flex">
                                <div className="my-auto">
                                    <form action="#" className="mailchimp-one__form mc-form" data-url="MAILCHIMP_FORM_URL" onSubmit={this.handleSubmit}>
                                        <input type="email" name="Email" id="Email" placeholder="" required/>
                                        <button type="submit" className="thm-btn h-100"><div className="font-size-24 lh-48"> {getTraducao("Cadastrar")}</div></button>
                                    </form>{/*<!-- /.mailchimp-one__form -->*/}
                                    <div className="mc-form__response"></div>{/*<!-- /.mc-form__response -->*/}
                                </div>{/*<!-- /.my-auto -->*/}
                            </Col>{/*<!-- /.col-lg-6 -->*/}
                        </Row>{/*<!-- /.row -->*/}
                    </Container>{/*<!-- /.container -->*/}
                </section>
            </div>
        );
    }
}
export default MailChimp;