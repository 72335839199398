import React, { Component } from 'react';

import "../assets/css/estilo.css";
import "../assets/css/sobre-nos.css";
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import SobreProjetoDataService from "../services/sobreProjeto.service";
import EquipeDataService from "../services/equipe.service";
import FichaTecnicaDataService from "../services/fichaTecnica.service";
import { Container, Row, Col } from 'react-bootstrap';
import getTraducao from '../utils/traducao';
import { scrollToSmooth } from '../utils';

export default class Sobre extends Component {
	constructor(props) {
		super(props);

		this.state = {
			SobreProjeto: getTraducao("Carregando dados do projeto").toUpperCase(),
			Equipes: [],
			FichasTecnica: []
		}
	}
	componentDidMount() {
		this.carregarSobre();
		this.carregarEquipe();
		this.carregarFichaTecnica();
		scrollToSmooth();
	}
	carregarSobre() {

		SobreProjetoDataService.getAll()
			.then(response => {
				var element = response.data;
				// console.log(element[0])
				this.setState({
					SobreProjeto: element[0].texto
				})

			})
			.catch(e => {
				console.log(e);
			});
	}
	carregarEquipe() {

		EquipeDataService.getAll()
			.then(response => {
				var element = response.data;
				if (this.state.Equipes.length === 0) {
					for (var i = 0; i < element.length; i++) {

						this.setState({
							Equipes: this.state.Equipes.concat(
								[
									{
										Id: element[i].id,
										Nome: element[i].nome,
										Texto: element[i].texto,
										Foto: element[i].foto,
										Colaborador: element[i].colaborador,

									}])
						})
					}
				}

			})
			.catch(e => {
				console.log(e);
			});
	}
	carregarFichaTecnica() {

		FichaTecnicaDataService.getAll()
			.then(response => {
				var element = response.data;
				if (this.state.FichasTecnica.length === 0) {
					for (var i = 0; i < element.length; i++) {

						this.setState({
							FichasTecnica: this.state.FichasTecnica.concat(
								[
									{
										Id: element[i].id,
										Titulo: element[i].titulo,
										Nome: element[i].nome,
										Ordem: element[i].ordem,
										Coluna: element[i].coluna,
									}])
						})
					}
				}

			})
			.catch(e => {
				console.log(e);
			});
	}
	render() {

		return (
			<div className="page-wrapper">
				<NavTop></NavTop>
				<NavMain></NavMain>
				<Header title="Sobre Nós" ></Header>
				{/* SOBRE O PROJETO */}
				<Container className="sobre-nos pt-4">
					<Row>
						<Col xs={6} className="">
							<h3>{getTraducao("SOBRE O")}</h3>
							<h1>{getTraducao("PROJETO")}</h1></Col>
						<Col xs={2} className=""></Col>
						<Col xs={4} className="">
						</Col>
					</Row>
					{/* </Container>
				<Container className="pt-5"> */}

					<Row className="pt-5">
						<Col lg={12}>
							<p className="about-two__text sobre-nos">{this.state.SobreProjeto}</p>{/*<!-- /.about-two__text -->*/}
						</Col>
					</Row>
				</Container>
				{/* EQUIPE */}
				<Container className="sobre-nos pt-4">
					<Row>
						<Col xs={12} className="">
							<h3>{getTraducao("EQUIPE DE").toUpperCase()} </h3>
							<Row>
								<Col ><h1>{getTraducao("PESQUISA & REDAÇÃO").toUpperCase()}</h1></Col>
								<Col className="pt-3 pb-3"><hr></hr></Col>
							</Row>

						</Col>
					</Row>
					<Row>
						<br></br>
					</Row>

					<Container className="sobre-nos ">
						<Row>
							{this.state.Equipes.length > 0
								?
								<>
									{
										this.state.Equipes.filter(filtro => filtro.Colaborador.toUpperCase().includes('N')).map(data =>
											<Col xs={12} sm={12} md={6} lg={4} className="item" key={data.Id}>
												<div className="course-one__single color-1">
													<div className="_course-one__image">
														<img src={"./assets/images/" + data.Foto} alt="" />
													</div>{/*<!-- /._course-one__image -->*/}
													<div className="_course-one__contsent">
														<h2 className="course-one__title font-size-36 lh-50 ">

															{data.Nome}
														</h2>

														<div className="_course-one__stars">
															<span className="_course-one__stars-wrap">

															</span>

														</div>{/*<!-- /._course-one__stars -->*/}
														<div className="_course-one__meta font-size-20 lh-21">

														</div>{/*<!-- /._course-one__meta -->*/}
														<div className="_course-one__meta-description font-size-18 lh-21">
															{data.Texto}
														</div>
														{/* <Link to="#" className="_course-one__link font-size-20 lh-21">Assinar</Link><!-- /.course-one__link --> */}
													</div>{/*<!-- /.course-one__content -->*/}
												</div>{/*<!-- /.course-one__single -->*/}
											</Col>
										)
									}

								</>
								:
								<p>{getTraducao("Carregando equipe").toUpperCase()}</p>
							}
						</Row>
					</Container>{/*<!-- /.container -->*/}

				</Container>
				{/* EQUIPE */}
				<Container className="sobre-nos pt-4">
					<Row>
						<Col xs={12} className="">
							<h3>{getTraducao("NOSSOS").toUpperCase()}</h3>
							<Row>
								<Col><h1>{getTraducao("COLABORADORES").toUpperCase()}</h1></Col>
								<Col className="pt-3 pb-3"><hr></hr></Col>
							</Row>

						</Col>
					</Row>
					<Row>
						<br></br>
					</Row>

					<Container className="sobre-nos ">
						<Row>
							{this.state.Equipes.length > 0
								?
								<>
									{
										this.state.Equipes.filter(filtro => filtro.Colaborador.toUpperCase().includes('S')).map(data =>
											<Col xs={12} sm={12} md={6} lg={4}  className="item" key={data.Id}>
												<div className="course-one__single color-1">
													<div className="_course-one__image">
														<img src={"./assets/images/" + data.Foto} alt="" />
													</div>{/*<!-- /._course-one__image -->*/}
													<div className="_course-one__contsent">
														<h2 className="course-one__title font-size-36 lh-50 ">

															{data.Nome}
														</h2>

														<div className="_course-one__stars">
															<span className="_course-one__stars-wrap">

															</span>

														</div>{/*<!-- /._course-one__stars -->*/}
														<div className="_course-one__meta font-size-20 lh-21">

														</div>{/*<!-- /._course-one__meta -->*/}
														<div className="_course-one__meta-description font-size-18 lh-21">
															{data.Texto}
														</div>
														{/* <Link to="#" className="_course-one__link font-size-20 lh-21">Assinar</Link><!-- /.course-one__link --> */}
													</div>{/*<!-- /.course-one__content -->*/}
												</div>{/*<!-- /.course-one__single -->*/}
											</Col>
										)
									}

								</>
								:
								<p>{getTraducao("Carregando equipe").toUpperCase()}</p>
							}
						</Row>
					</Container>{/*<!-- /.container -->*/}

				</Container>
				<Container className="sobre-nos orange py-5" fluid={true}>
					<Container className="pt-4">
						<Row>
							<Col xs={12} className="">
								<h3 className="white">{getTraducao("FICHA").toUpperCase()}</h3>
								<h1>{getTraducao("TÉCNICA").toUpperCase()}</h1>
							</Col>
						</Row>
						<Row className="pt-5">
							<Col xs={12} sm={12} md={12} lg={7} >
								<Container className="pt-4">
									<Row>
										{this.state.FichasTecnica.length > 0
											?
											<>
												{
													this.state.FichasTecnica.filter(filtro => filtro.Coluna === 1).map(data =>
														<Col lg={12} key={data.Id} className={"pb-3"}>
															<h6 className="ficha-tecnica titulo">{data.Titulo.trim()}</h6>
															<h6 className="ficha-tecnica nome">{data.Nome.trim()}</h6>
														</Col>
													)
												}

											</>
											:
											<p>{getTraducao("Carregando Ficha Técnica").toUpperCase()}</p>
										}
									</Row>
								</Container>
							</Col>
							<Col xs={12} sm={12} md={12} lg={5} >
								<Container className="pt-4">
									<Row>
										{this.state.FichasTecnica.length > 0
											?
											<>
												{
													this.state.FichasTecnica.filter(filtro => filtro.Coluna === 2).map(data =>
														<Col lg={12} key={data.Id} className={"pb-3"}>
															<h6 className="ficha-tecnica titulo">{data.Titulo.trim()}</h6>
															<h6 className="ficha-tecnica nome">{data.Nome.trim()}</h6>
														</Col>
													)
												}

											</>
											:
											<p>{getTraducao("Carregando Ficha Técnica").toUpperCase()}</p>
										}
									</Row>

								</Container>
							</Col>
						</Row>

					</Container>
				</Container>
				<Container>
					<Row>

						<Col lg={12} className={"text-justify py-5"}>
							<p  style={{whiteSpace: "break-Spaces"}}>{getTraducao("Todos os direitos reservados.")}</p>
						</Col>
					</Row>
				</Container>
				<FooterMenu></FooterMenu>
				<Footer></Footer>
			</div>
		);
	}

}
