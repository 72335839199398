import React, { Component } from 'react';
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container, Row, Col } from 'react-bootstrap';
import img01 from "../assets/images/opus com assinatura 2020.png"
import img02 from "../assets/images/Logo Instituto Joanir Zonta-com tag.png"
import img03 from "../assets/images/Vector Smart Object.png"
import img04 from "../assets/images/Vector Smart Object-1.png"
import getTraducao from '../utils/traducao';

class Fase01 extends Component {
    constructor(props) {
        super(props);
        ;

    }

    handleLogout = () => {

    }

    componentDidMount() {


    }

    render() {

        return (
            <div>

                <section className="fase-one">
                    <Container>
                        <Row>
                            <Col xs={4} sm={2} md={2} lg={4} className={"font-italic pt-5 pb-5 text-right font-size-19 lh-76 text-uppercase"}>
                                <div className={"divisoria"}>{getTraducao("FASE 1")}</div>
                            </Col>{/*<!-- /.col-lg-6 -->*/}
                            <Col xs={8} sm={10} md={10} lg={8} className="d-flex pt-4 mt-3 pb-3 px-0">
                                <Container fluid={true}>
                                    <Row>
                                        <Col lg={12}>{getTraducao("Incentivo")}</Col>
                                    </Row>
                                    <Row className="pb-2">
                                         <img  alt="img04"  className={"px-4 py-2 pt-4 text-right"} src={img04} />
                                         <img  alt="img01"  className={"px-4 py-2 pt-4"} src={img01} />
                                         <img  alt="img02"  className={"px-4 py-2 pt-4"} src={img02} />
                                         <img  alt="img03"  className={"px-4 py-2 "} src={img03} />
                                    </Row>
                                    <Row>
                                        <Col><div className="font-size-14 lh-14">{getTraducao("Projeto realizado com recursos do Programa de Apoio e Incentivo à Cultura - Fundação Cultural de Curitiba e da Prefeitura Municipal de Curitiba.")}</div></Col>
                                    </Row>
                                </Container>
                            </Col>{/*<!-- /.col-lg-6 -->*/}
                        </Row>{/*<!-- /.row -->*/}
                    </Container>{/*<!-- /.container -->*/}
                </section>
            </div>
        );
    }
}
export default Fase01;