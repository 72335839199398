import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import BannerPlanosInterna from './BannerPlanosInterna';

export default class Assinatura extends Component {

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }
  render() {

    return (
      <div className="page-wrapper">
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Assinatura" ></Header>
        <BannerPlanosInterna></BannerPlanosInterna>
        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div>
    );
  }

}
