import React, { Component } from 'react';
import PlanoDataService from "../services/plano.service";
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import TextInput from './TextInput';
// import Select from './Select';
import Botao from './Botao';
import LoginDataService from "../services/login.service";
import UserDataService from "../services/user.service";
import { DirectPayment } from 'pagseguro-react';
import psgService from '../services/psg.service';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Header from './Header';
import FooterMenu from './FooterMenu';
import Footer from './Footer';
import getTraducao from '../utils/traducao';
import { getEnv, getEnvPagSeguro } from '../utils';
import { getError } from '../utils/error';
import { Loading } from '../Ui';
class NovaAssinatura extends Component {



    constructor(props) {
        super(props);
        this.state = {
            // messages: [],
            message: [],
            escolhas: [],
            select: 0,
            planos: [],
            Plano: -1,
            Sexo: "",
            loading: false,

            // {/*
            //     this.state = {
            //     planos: [],
            //     message: [],
            //     cadastro: {},
            //     escolhas: [],
            session: '-1',
            // env: 'sandbox',
            env: getEnvPagSeguro(),

            sender: {
                name: '',
                email: '',
                phone: {
                    areaCode: '',
                    number: '',
                },
                document: {
                    type: '',
                    value: ''
                },
            },

            shipping: {
                type: 3,
                cost: 0.00,
                street: '',
                number: null,
                complement: '',
                district: '',
                city: '',
                state: '',
                country: 'Brasil',
                postalCode: ''
            },

            billing: {
                street: '',
                number: 0,
                complement: '',
                district: '',
                city: '',
                state: '',
                country: 'Brasil',
                postalCode: ''
            },

            items: [],
            exclude: ['ONLINE_DEBIT', 'BOLETO'],
            hiddenShippingForm: 0,
            hiddenBillingForm: 0,
            hiddenSenderForm: 0,
            creditCard: {
                maxInstallmentNoInterest: 12 // parcelas com desconto
            },
            extraAmount: 0.00,
            reference: ''
        };

        this.setPlano = this.setPlano.bind(this);
        this.setSexo = this.setSexo.bind(this);
        this.setPlanoDataKey = this.setPlanoDataKey.bind(this);
        this.onFocusDate = this.onFocusDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.voltar = this.voltar.bind(this);
        this.clickIcone = this.clickIcone.bind(this);
        this.isPlanosSelecionado = this.isPlanosSelecionado.bind(this);
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    clickIcone = e => {
        this.setState({ Anexo: e.target.getAttribute('data-key') })
        this.openModal()
    }
    isJson(item) {

        item = typeof item !== "string"
            ? JSON.stringify(item)
            : item;

        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }

        if (typeof item === "object" && item !== null) {
            return true;
        }

        return false;
    }

    onSubmit(event) {
        //console.log(event)

        this.setState({
            loading: true,
            error: null,
            success: '',
            paymentLink: null
        })

        psgService.createPayments(event)
            .then(response => {

                this.setState({
                    submitted: true,
                    mensagem: response
                });

                const { content } = response.data

                this.setState({
                    paid: true,
                    loading: false
                })
                alert('O seu plano, está sendo processado, aguarde..');
                window.location = "/minhas-assinaturas";
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                const content = err.response.data.error;
                var XMLParser = require('react-xml-parser');
                var xml = new XMLParser().parseFromString(content);    // Assume xmlText contains the example XML
                const error = xml.getElementsByTagName('error')[0].getElementsByTagName("code")[0].value
                const message = xml.getElementsByTagName('error')[0].getElementsByTagName("message")[0].value
                console.log(error, message)
                alert(getError(error) + "(" + message + ")")
            });

    }
    onError(event) {
        alert(event)
    }
    handleLogout = () => {

    }

    componentDidMount() {
        this.getSender();
        this.getPlanos();
        this.getSession();

        this.setState({
            Plano: (this.props.match.params.plano !== undefined ? this.props.match.params.plano : -1)
        })

    }
    getPlanos() {
        PlanoDataService.getAll()
            .then(response => {
                this.setState({
                    planos: response.data
                });
                response.data.map(e => {
                    this.setState({
                        escolhas: [...this.state.escolhas, { 'id': e.id, 'value': 0, 'parcelas': e.parcelas, 'titulo': e.titulo, 'descricao': e.descricao, valor: e.valor }]
                    })
                    // console.log(e.id)
                    return e.id
                })
                this.setState({ message: [] })


            })
            .catch(e => {
                console.log(e);
            });
    }

    getSender() {
        UserDataService.get()
            .then(response => {
                let userData = response.data;

                this.setState({

                    sender: {
                        name: userData.nome !== null ? userData.nome : "",
                        email: userData.email !== null ? userData.email : "",
                        phone: {
                            areaCode: userData.areaCode !== null ? userData.areaCode.replaceAll('(', '').replaceAll(')', '') : "",
                            number: userData.number !== null ? userData.number.replaceAll('.', '').replaceAll('-', '') : "",
                        },
                        // document: {
                        //     type: (userData.CPF.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').length > 11 ? 'CNPJ' : 'CPF'),
                        //     value: userData.CPF.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
                        // },
                    },

                    shipping: {
                        type: 3,
                        cost: 0.00,
                        street: userData.Endereco !== null ? userData.Endereco : "",
                        number: userData.Numero !== null ? userData.Numero : "",
                        complement: userData.Complemento !== null ? userData.Complemento : "",
                        district: userData.Bairro !== null ? userData.Bairro : "",
                        city: userData.Cidade !== null ? userData.Cidade : "",
                        state: userData.Estado !== null ? userData.Estado : "",
                        country: userData.Pais !== null ? (userData.Pais !== "" ? userData.Pais : "Brasil") : "Brasil",
                        postalCode: userData.CEP !== null ? userData.CEP.replace('.', '').replace('-', '') : "",
                    },

                    billing: {
                        street: userData.Endereco !== null ? userData.Endereco : "",
                        number: userData.Numero !== null ? userData.Numero : "",
                        complement: userData.Complemento !== null ? userData.Complemento : "",
                        district: userData.Bairro !== null ? userData.Bairro : "",
                        city: userData.Cidade !== null ? userData.Cidade : "",
                        state: userData.Estado !== null ? userData.Estado : "",
                        country: userData.Pais !== null ? (userData.Pais !== "" ? userData.Pais : "Brasil") : "Brasil",
                        postalCode: userData.CEP !== null ? userData.CEP.replace('.', '').replace('-', '') : "",
                    },
                })



            })
            .catch(e => {
                console.log(e);
            });
    }

    getPlano(id) {
        PlanoDataService.get(id)
            .then(response => {
                let e = response.data
                this.setState({
                    items: [{
                        id: e.id,
                        description: e.titulo,
                        quantity: 1,
                        amount: e.valor,
                    }],

                    creditCard: {
                        maxInstallmentNoInterest: e.parcelas // parcelas com desconto
                    },
                    reference: e.titulo
                });
                // console.log(this.state.items)

                this.setState({ message: [] })


            })
            .catch(e => {
                console.log(e);
            });
    }

    getSession() {

        // console.log(this.state.items)

        if (this.state.session === '-1') {
            psgService.getSession()
                .then(response => {
                    var XMLParser = require('react-xml-parser');
                    var xml = new XMLParser().parseFromString(response.data);    // Assume xmlText contains the example XML

                    this.setState({
                        session: xml.getElementsByTagName('id')[0].value
                    });
                    // console.log(response.data)
                    this.setState({ message: [] })
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        session: '-1'
                    });
                });
        }
    }
    voltar(event) {
        this.setState({ Plano: -1 })
    }
    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        let cadastro =
        {
            nome: event.target.Nome.value,
            escola: event.target.Escola.value,
            CPF: event.target.CPF.value,
            dataNascimento: event.target.DataNascimento.value,
            Profissao: event.target.Profissao.value,
            Sexo: event.target.Sexo.value,
            email: event.target.Email.value,
            // username: event.target.username.value,
            password: event.target.Senha.value,
            CEP: event.target.CEP.value,
            Endereco: event.target.Endereco.value,
            Numero: event.target.Numero.value,
            Complemento: event.target.Complemento.value,
            Bairro: event.target.Bairro.value,
            Cidade: event.target.Cidade.value,
            Estado: event.target.Estado.value,
            Pais: event.target.Pais.value,

        };
        this.setState({ message: [] })
        let messages = []

        if (this.state.Plano === 0)
            messages.push({ 'id': 3, 'value': 'Favor escolher um plano.' })

        if (event.target.Senha.value !== event.target.SenhaConfirma.value)
            messages.push({ 'id': 4, 'value': 'As senhas devem ser iguais.' })

        this.setState({ message: messages })

        if (messages.length === 0)
            LoginDataService.signup(cadastro).then(res => {
                if (res.status === 200) {
                    // console.log(res.data);

                    window.location = "/login";
                }
            }).catch(function (error) {

                let message = 'Ocorreu um erro inesperado.';
                if (error.response !== undefined) {
                    message = error.response.data.message;
                }
                else
                    if (error.message !== undefined) {
                        message = error.message;
                    }


                messages.push({ 'id': 4, 'value': message })
                this.setState({ message: message });
            });

    }

    onFocusDate(event) {
        event.target.type = (event.target.type === 'text' ? 'date' : event.target.type)
    }

    setPlano(event) {
        this.setState({
            Plano: event.target.value
        });
    }
    setSexo(event) {

        this.setState({
            Sexo: event.target.value
        });
    }

    setPlanoDataKey(event) {

        let remove = this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key));
        this.state.escolhas.map(data => (data.value = 0));
        this.setState({
            escolhas: [...this.state.escolhas.filter((_, i) => i !== remove), { id: parseInt(event.target.dataset.key), value: 1 }],
            Plano: event.target.dataset.key//event.target.getAttribute('data-key')
        })
        this.getPlano(parseInt(event.target.dataset.key))
        this.getSession();
        console.log(this.state.items)

    }

    isPlanosSelecionado(value) {

        let select = this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(value));

        let retorno = -2
        if ((this.state.escolhas !== undefined) && (select !== -1))
            retorno = this.state.escolhas.filter((_, i) => i === select)[0].value
        return retorno;
    }
    render() {
        return (


            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Nova Assinatura" ></Header>

                {/* <form onSubmit={this.handleSubmit}> */}

                <section className="course-osne__top-titsle home-sone assinatura-tops pt-5">
                    <div className="container ">
                        <Row>
                            <Col></Col>
                            <Col xs={10}>
                                <div className="block-title mb-0 font-size-24 lh-38 ">
                                    {getTraducao("Oferecemos a você ou à sua instituição de ensino ou outra, algumas opções de planos para que você possa escolher o que melhor se adapta à sua necessidade.")}<br></br><br></br>
                                    {getTraducao("Em todos os planos você tem o acesso a todos os recursos do dicionário constituindo de verbetes, fotos, vídeos, áudios e ilustrações. A diferença de um plano para o outro consiste somente no período de tempo que você terá disponibilidade de acesso e o custo.")}<br></br><br></br>
                                    <h2 className="block-dtitle__titsle font-size-30">
                                        {getTraducao("Escolha a sua opção e faça a sua assinatura!")}</h2>{/*<!-- /.block-title__title -->*/}
                                </div>{/*<!-- /.block-title -->*/}
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>{/*<!-- /.container -->*/}

                </section>{/*<!-- /.course-one__top-title -->*/}
                {/* <section className="course-ones course-one__teacher-details assinatura-planoss home-one"> */}
                <Container fluid={false} className="mt-2 pt-5 pb-5  ">

                    {false
                        ?
                        <Row>
                            <Col xs={12}>
                                <div className={"has-error control-label"}>
                                    <ul>
                                        <>
                                            {

                                                this.state.message.map((a, i) => <li key={i}>{a.value}</li>)
                                            }
                                        </>
                                    </ul>
                                </div>
                            </Col>

                        </Row>
                        :
                        <>
                            {
                                getEnv() !== 'production'
                                    ?
                                    `SELECIONE UM PLANO ${this.state.Plano}
                                   | Parcelas sem juros ${this.state.creditCard.maxInstallmentNoInterest}
                                   | SESSION ${this.state.session}
                                   | PLANO ${this.state.escolhas.length}
                                   | SELECT ${this.state.select}
                                   | ITEMS ${this.state.items.length}
                                   | ENV ${this.state.env}
                                   | reference ${this.state.reference}`
                                    : ''
                            }
                            {/* {this.state.Plano === 0 && this.state.session === '-1'}<br />
                                {this.state.Plano}<br />
                                {this.state.session} */}
                            <Row className='pb-3'>
                                <Col xs={6} sm={6} md={2} lg={2}>
                                    {this.state.Plano > 0 && this.state.session !== '-1'
                                        ?
                                        <Botao
                                            FunctionClickCall={this.voltar}
                                            BotaoLink={true}
                                            className={"course-one__link font-size-20 lh-21"}
                                            value={getTraducao("Escolher outro plano")} />
                                        : <></>
                                    }
                                </Col>

                                <Col xs={10}>
                                </Col>
                            </Row>
                            {/* {this.state.Plano === -1 || this.state.session === '-1'
                                    ? */}
                            <Row>

                                {this.state.planos && this.state.planos.length > 0
                                    ?
                                    <>
                                        {
                                            this.state.planos.map(data =>
                                                // xs={12} sm={12} md={6} lg={6}
                                                <Col xs={12}
                                                    sm={12}
                                                    md={((this.state.Plano === -1 || this.state.session === '-1') ? 4 : this.isPlanosSelecionado(parseInt(data.id)) === 1 ? 12 : 4)}
                                                    lg={((this.state.Plano === -1 || this.state.session === '-1') ? 4 : this.isPlanosSelecionado(parseInt(data.id)) === 1 ? 12 : 4)}
                                                    className={"item mx-auto px-auto " + ((this.state.Plano === -1 || this.state.session === '-1') ? 'd-block' : this.isPlanosSelecionado(parseInt(data.id)) === 1 ? 'd-block' : 'd-none')} key={data.id}>
                                                    <div className="course-one__single color-1 ">
                                                        <div className={"course-one__image color-" + data.order}>
                                                            {/* <img src={"./assets/images/" + data.imagem} alt="" /> */}
                                                        </div>{/*<!-- /.course-one__image -->*/}
                                                        <div className="course-one__content">
                                                            <h2 className="course-one__title font-size-36 lh-50 "> {data.titulo}</h2>

                                                            <div className="course-one__stars">
                                                                <span className="course-one__stars-wrap">

                                                                </span>
                                                                {/*<!--span className="course-one__count">4.8</span-->*/}{/*<!-- /.course-one__count -->*/}
                                                                {/*<!--span className="course-one__stars-count">250</span-->*/}{/*<!-- /.course-one__stars-count -->*/}
                                                            </div>{/*<!-- /.course-one__stars -->*/}
                                                            <div className="course-one__meta font-size-36 lh-36">
                                                                R$ {data.valor}<br />
                                                            </div>{/*<!-- /.course-one__meta -->*/}
                                                            <div className={"course-one__meta-description-interna  font-size-18 lh-21 " + (this.state.Plano > 0 && this.state.session !== '-1' ? 'selecionado' : 'non-selecionado')}>
                                                                {data.conteudo}

                                                            </div>
                                                            {this.state.Plano === -1 || this.state.session === '-1'
                                                                ?
                                                                <Link to="#" index={data.id} data-key={data.id} id={data.id} disabled={this.state.Plano > 0} onClick={this.setPlanoDataKey} className={"course-one__link font-size-20 lh-21 " + (this.isPlanosSelecionado(parseInt(data.id)) === 1 ? 'active ' : 'non-active')} >{getTraducao("Escolher")}</Link>
                                                                :
                                                                <></>}
                                                            {/*<!-- /.course-one__link -->*/}
                                                        </div>{/*<!-- /.course-one__content -->*/}
                                                    </div>{/*<!-- /.course-one__single -->*/}
                                                </Col>
                                            )
                                        }

                                    </>

                                    :
                                    <Container>
                                        <Row>
                                            <Col>
                                                <p>{getTraducao("Carregando planos").toUpperCase()}</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                            </Row>
                            <Row>
                                <Col className='text-right'>
                                    <Link to="../codigo-ativacao" className='course-one__title block-dtitle__titsle font-size-30 ' >{getTraducao('Tenho código de assinatura')}</Link>
                                </Col>
                            </Row>
                            {/* : <></>
                                } */}
                            {this.state.Plano > 0 && this.state.session !== '-1'
                                ?
                                <>
                                    <Row className={" pt-0 mt-0 " }>
                                        <Col className={!this.state.loading ? "d-block" : "d-none"} col={12}>

                                            <DirectPayment
                                                env={this.state.env}
                                                session={this.state.session}
                                                extraAmount={this.state.extraAmount}
                                                reference={this.state.reference}
                                                creditCard={this.state.creditCard}
                                                sender={this.state.sender}
                                                shipping={this.state.shipping}
                                                billing={this.state.billing}
                                                items={this.state.items}
                                                exclude={this.state.exclude}
                                                hiddenShippingForm={this.state.hiddenShippingForm}
                                                hiddenBillingForm={this.state.hiddenBillingForm}
                                                hiddenSenderForm={this.state.hiddenSenderForm}
                                                onError={this.onError.bind(this)}
                                                onSubmit={this.onSubmit.bind(this)}
                                            />



                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>{this.state.loading ? <Loading></Loading> : <></>}</Col>
                                    </Row>
                                </>
                                :
                                <></>
                            }
                        </>
                    }

                </Container>
                {/* </section> */}
                <FooterMenu></FooterMenu>
                <Footer></Footer>

            </div>
        );
    }
}
export default NovaAssinatura;