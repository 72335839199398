import { getLang } from "../utils";
import http from "../utils/apiAvancada";

class EquipeDataService {
  getAll() {
    return http.get(`/equipe?lang=${getLang()}`);
  }

}

export default new EquipeDataService();